import {
    faArrowLeft,
    faArrowsLeftRight,
    faComments,
    faDownload,
    faPaperclip,
    faPlus,
    faTrash,
    faUpDown,
    faUpRightAndDownLeftFromCenter,
    faWeightHanging,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton, Typography } from '@mui/material';
import { FormInputFile } from 'components/Shared/FormComponents/FormInputFile';
import { FormInputText } from 'components/Shared/FormComponents/FormInputText';
import useSession from 'hooks/useSession';
import { Dispatch, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import restAPI from '../../../services/rest-api';
import StackingContent from '../StackingContent/StackingContent.module';
import styles from './PackageDetail.module.scss';

function PackageDetail(props: PackageDetailProps) {
    const { setSelectedPackage } = props;
    const { t } = useTranslation('common');
    const [packageObj, setPackageObj] = useState(props.packageObj);
    const { getSession } = useSession();
    const [isDraggedUpon, setIsDraggedUpon] = useState(false);
    const [, setFormValues] = useState();
    const { control, getValues, setValue } = useForm<any>({
        defaultValues: {
            documents: [],
            comments: packageObj.details.comments,
        },
        mode: 'onChange',
    });

    const getClassForExtension = (fileType: string) => {
        if (fileType?.includes('image')) {
            return 'image';
        } else if (fileType?.includes('pdf')) {
            return 'pdf';
        }
    };

    const handleAttachmentsAdd = (e: any) => {
        const dt = new DataTransfer();
        e.forEach((file: any) => dt.items.add(file));
        // TODO: hook up to API call
        // restAPI.uploadDocumentsToNode(packageObj._id, dt.files).then((filesResult) => {
        //     setValue('documents', filesResult.documents || null);
        //     setFormValues(filesResult.documents);
        // }).catch((err) => {
        //     console.error(err);
        // });
    };

    const handleFileDeletion = (e: any, fileData: any) => {
        e.stopPropagation();
        // TODO: hook up to API call
        // restAPI.deleteFileFromNode(packageObj._id, fileData.name).then((result) => {
        //     setValue('documents', result?.documents?.length > 0 ? result.documents : null);
        // }).catch((err) => {
        //     console.error(err);
        // });
    };

    const handleFilePreview = (file: any) => {
        if (!file.path) {
            return;
        }

        const url = restAPI.getFileDownloadURL(file.path, getSession()?.token);
        window.open(url);
    };

    const handleFileDownload = (e: any, file: any) => {
        e.stopPropagation();
        e.preventDefault();
        const path = restAPI.getFileDownloadURL(file.path, getSession()?.token);
        fetch(path)
            .then((res) => res.blob())
            .then((blob) => {
                // Create link element to download file
                let url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                document.body.appendChild(link);
                link.href = url;
                link.download = file.name;

                // Start download
                link.click();

                // Cleanup
                window.URL.revokeObjectURL(url);
                document.body.removeChild(link);
            })
            .catch((err) => {
                console.error('Could not download file!', err);
            });
    };

    const handleDragEnter = (e: any) => {
        e.preventDefault();
        if (!isDraggedUpon) {
            setIsDraggedUpon(true);
        }
    };

    const handleDragLeave = (e: any) => {
        if (!e.relatedTarget || e.currentTarget.contains(e.relatedTarget)) {
            return;
        }
        e.preventDefault();
        e.stopPropagation();
        setIsDraggedUpon(false);
    };

    const handleDrop = (e: any) => {
        setIsDraggedUpon(false);
        if (e.dataTransfer?.files?.length > 0) {
            handleAttachmentsAdd(Array.from(e.dataTransfer.files));
        }
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDragOver = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleContentDelete = (key: string) => {
        setPackageObj({ ...packageObj, ...{ items: packageObj.items.filter((value) => value.id !== key) } });
    };

    return (
        <PerfectScrollbar
            id="package-container"
            className={`${styles['package-container']} ${isDraggedUpon && styles['container-dragged']}`}
            onDrop={handleDrop}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragOver={handleDragOver}>
            <Box className={styles['header-container']}>
                <FontAwesomeIcon icon={faArrowLeft} onClick={() => setSelectedPackage(null)} />
                <Typography>{packageObj.name}</Typography>
            </Box>
            <Box className={styles['content-container']}>
                <Box className={styles['left-content-container']}>
                    <Box className={styles['detail-container']}>
                        <Box className={styles['detail-header']}>
                            <FontAwesomeIcon icon={faWeightHanging} />
                            <Typography>{t('PackageDetail.Weight')}</Typography>
                        </Box>
                        <Box className={styles['detail-value']}>{packageObj.details.weight || '-'}</Box>
                    </Box>
                    <Box className={styles['detail-container']}>
                        <Box className={styles['detail-header']}>
                            <FontAwesomeIcon icon={faArrowsLeftRight} />
                            <Typography>{t('PackageDetail.Length')}</Typography>
                        </Box>
                        <Box className={styles['detail-value']}>{packageObj.details.length || '-'}</Box>
                    </Box>
                    <Box className={styles['detail-container']}>
                        <Box className={styles['detail-header']}>
                            <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
                            <Typography>{t('PackageDetail.Width')}</Typography>
                        </Box>
                        <Box className={styles['detail-value']}>{packageObj.details.width || '-'}</Box>
                    </Box>
                    <Box className={styles['detail-container']}>
                        <Box className={styles['detail-header']}>
                            <FontAwesomeIcon icon={faUpDown} />
                            <Typography>{t('PackageDetail.Height')}</Typography>
                        </Box>
                        <Box className={styles['detail-value']}>{packageObj.details.height || '-'}</Box>
                    </Box>
                    <Box className={`${styles['detail-container']} ${styles['detail-container-row']}`}>
                        <Box className={styles['detail-header']}>
                            <FontAwesomeIcon icon={faComments} />
                            <Typography>{t('PackageDetail.Comments')}</Typography>
                        </Box>
                        <FormInputText
                            control={control}
                            name={'comments'}
                            multiline
                            minRows={3}
                            maxRows={3}
                            placeholder="Comments"
                            classes={{ root: styles['comment-input'] }}
                        />
                    </Box>
                    <Box className={`${styles['detail-container']} ${styles['detail-container-row']} ${styles['attachments-container']}`}>
                        <Box className={styles['detail-header']}>
                            <FontAwesomeIcon icon={faPaperclip} />
                            <Typography>{t('PackageDetail.Attachments')}</Typography>
                            {getValues('documents').length > 0 && (
                                <>
                                    <Typography className={styles['attachments-amount']}>{getValues('documents').length}</Typography>
                                    <FormInputFile
                                        multiple
                                        control={control}
                                        name="documents"
                                        classes={{
                                            container: styles['inline-input-wrapper'],
                                            'input-container': styles['inline-input-container'],
                                        }}
                                        helperIcon={<FontAwesomeIcon icon={faPlus} />}
                                        onChange={(e) => handleAttachmentsAdd(e)}
                                    />
                                </>
                            )}
                        </Box>
                        {getValues('documents').length > 0 ? (
                            <PerfectScrollbar
                                className={styles['scrollable-attachments']}
                                options={{ useBothWheelAxes: true, suppressScrollX: false, suppressScrollY: false }}>
                                {getValues('documents').map((attachment, index) => {
                                    return (
                                        <Box
                                            className={`${styles['attachment']} ${styles[getClassForExtension(attachment.type)]}`}
                                            sx={{ cursor: attachment.path ? 'pointer' : 'default' }}
                                            key={`${index} ${attachment.name}`}
                                            onClick={() => handleFilePreview(attachment)}>
                                            <Box className={styles['attachment-information']}>
                                                <Box>{attachment.name?.split('.').pop()}</Box>
                                                <Typography title={attachment.name}>{attachment.name?.replace(/\.[^/.]+$/, '')}</Typography>
                                            </Box>
                                            <Box className={styles['attachment-actions']}>
                                                {attachment.path && (
                                                    <IconButton aria-label="delete" onClick={(e) => handleFileDownload(e, attachment)}>
                                                        <FontAwesomeIcon icon={faDownload} />
                                                    </IconButton>
                                                )}
                                                <IconButton onClick={(e) => handleFileDeletion(e, attachment)}>
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </IconButton>
                                            </Box>
                                        </Box>
                                    );
                                })}
                            </PerfectScrollbar>
                        ) : (
                            <FormInputFile
                                multiple
                                name="documents"
                                control={control}
                                classes={{
                                    container: styles['attachments-input-wrapper'],
                                    'input-container': styles['attachments-input-container'],
                                }}
                                helperText={t('IssueDialog.AddAttachmentsHelp')}
                                helperIcon={<FontAwesomeIcon icon={faPaperclip} />}
                                onChange={(e) => handleAttachmentsAdd(e)}
                            />
                        )}
                    </Box>
                </Box>
                <Box className={styles['vertical-divider']}></Box>
                <Box className={styles['right-content-container']}>
                    <Box className={styles['package-content-header']}>
                        <Typography>{t('PackageDetail.ItemsTitle', { name: packageObj.name })}</Typography>
                        <Typography>{t('PackageDetail.ItemsSubtitle', { amount: packageObj.items.length })}</Typography>
                    </Box>
                    <Box className={`${styles['package-content-wrapper']} ${packageObj.items.length === 0 ? styles['no-content'] : ''}`}>
                        {packageObj.items.length > 0 ? (
                            packageObj.items.map((entity) => {
                                return <StackingContent key={entity.id} entity={entity} variant="dark" onDelete={handleContentDelete} />;
                            })
                        ) : (
                            <Typography variant="subtitle1">{t('PackageDetail.GetStarted')}</Typography>
                        )}
                    </Box>
                </Box>
            </Box>
        </PerfectScrollbar>
    );
}

export default PackageDetail;

export interface PackageDetailProps {
    packageObj: any;
    setSelectedPackage: Dispatch<string>;
}
