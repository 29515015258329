import { faCubesStacked } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdjustIcon from '@mui/icons-material/Adjust';
import DomainDisabledIcon from '@mui/icons-material/DomainDisabled';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import LinkIcon from '@mui/icons-material/Link';
import SelectAllIcon from '@mui/icons-material/SelectAll';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import StraightenIcon from '@mui/icons-material/Straighten';
import VisibilityIcon from '@mui/icons-material/Visibility';
import WarningIcon from '@mui/icons-material/Warning';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import { Box, Button, ToggleButtonGroup, Typography, styled } from '@mui/material';
import MuiButton from '@mui/material/Button';
import MuiToggleButton from '@mui/material/ToggleButton';
import importSetCreatedAtom from 'atoms/importSetCreatedAtom';
import { FormInputFile } from 'components/Shared/FormComponents/FormInputFile';
import Requires from 'components/Shared/Permissions/Requires';
import { MeasureTool, MeasurementMode, SceneLayerName } from 'hsbshareviewer';
import { SelectionTool } from 'hsbshareviewer/src/model.api/modelviewer/Tools/SelectionTool';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { TFunction, useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import ImportSetDialog from '../ImportSets/ImportSetDialog';
import { getModelReferences } from '../ImportSets/importSetEntityHelper';
import IssueDialog from '../Issues/IssueDialog';
import { ContextualToolbarProps, ToolBarProps } from '../ToolBarProps';
import styles from './Toolbar.module.scss';

type Tools = 'selection' | 'measure' | 'section' | 'none';
enum Sides {
    Front = 1 << 0,
    Back = 1 << 1,
    Left = 1 << 2,
    Right = 1 << 3,
    Top = 1 << 4,
    Bottom = 1 << 5,
}

function SelectionToolbarContent(
    t: TFunction<'common', undefined>,
    handleToolChange: (event: React.MouseEvent<HTMLElement, MouseEvent>, value: Tools) => void,
    selectionTool: SelectionTool,
    multiSelect: [boolean, React.Dispatch<React.SetStateAction<boolean>>],
    selectionMode: ['entities' | 'elements', React.Dispatch<React.SetStateAction<'entities' | 'elements'>>],
    onSelectionModeToggle?: (mode: 'elements' | 'entities') => void,
    onMultiSelectToggle?: (state: boolean) => void
) {
    const [isMultiSelect, setMultiSelect] = multiSelect;
    const [isSelectionMode, setSelectionMode] = selectionMode;

    const ToggleButton = styled(MuiToggleButton)({
        '&.Mui-selected, &.Mui-selected:hover': {
            color: 'rgba(0, 0, 0, 0.54)',
            backgroundColor: 'rgba(249,175,8,.4)',
        },
    });

    const ElementIcon = () => {
        return <img src={process.env.PUBLIC_URL + '/icons/cubes-solid.svg'} alt="Icon for elements" height={'auto'} width={16} />;
    };

    const EntityIcon = () => {
        return <img src={process.env.PUBLIC_URL + '/icons/cube-solid.svg'} alt="Icon for entities" height={'auto'} width={16} />;
    };

    const handleMultiSelectChange = () => {
        setMultiSelect(!isMultiSelect);
        // Check for false cause this will change next render frame to true
        selectionTool.MultiSelect = !isMultiSelect;
        if (onMultiSelectToggle) onMultiSelectToggle(!isMultiSelect);
        handleToolChange(null, 'selection');
    };

    const handleSelectionModeChange = (value: 'entities' | 'elements') => {
        setSelectionMode(value);
        // Check for false cause this will change next render frame to true
        selectionTool.SelectElement = isSelectionMode !== 'elements';
        if (onSelectionModeToggle) onSelectionModeToggle(isSelectionMode);
        handleToolChange(null, 'selection');
    };

    return (
        <Box className={styles.actionContainer}>
            <ToggleButton
                selected={isMultiSelect}
                onChange={handleMultiSelectChange}
                value="multiSelect"
                className={styles['extra-button']}>
                <SelectAllIcon />
                <Typography>{t('Toolbar.MultiSelect')}</Typography>
            </ToggleButton>
            <ToggleButton
                selected={isSelectionMode === 'entities'}
                value="entitySelect"
                onChange={() => handleSelectionModeChange('entities')}>
                <EntityIcon />
                <Typography>{t('Toolbar.Entity')}</Typography>
            </ToggleButton>
            <ToggleButton
                selected={isSelectionMode === 'elements'}
                onChange={() => handleSelectionModeChange('elements')}
                value="elementSelect">
                <ElementIcon />
                <Typography>{t('Toolbar.Element')}</Typography>
            </ToggleButton>
        </Box>
    );
}

function MeasureToolbarContent(
    t: TFunction<'common', undefined>,
    handleToolChange: (event: React.MouseEvent<HTMLElement, MouseEvent>, value: Tools) => void,
    measureTool: MeasureTool,
    measureMode: ['distance' | 'angle', React.Dispatch<React.SetStateAction<'distance' | 'angle'>>]
) {
    const [currentMeasureMode, setcurrentMeasureMode] = measureMode;

    const ToggleButton = styled(MuiToggleButton)({
        '&.Mui-selected, &.Mui-selected:hover': {
            color: 'rgba(0, 0, 0, 0.54)',
            backgroundColor: 'rgba(249,175,8,.4)',
        },
    });

    const handleMeasureModeChange = (event: React.MouseEvent<HTMLElement, MouseEvent>, value: 'distance' | 'angle') => {
        setcurrentMeasureMode(value);
        measureTool.SetMeasureMode(value === 'distance' ? MeasurementMode.DISTANCE : MeasurementMode.ANGLE);
        handleToolChange(null, 'measure');
    };

    return (
        <Box className={styles.actionContainer}>
            <ToggleButton selected={currentMeasureMode === 'distance'} onChange={handleMeasureModeChange} value={'distance'}>
                <StraightenIcon />
                <Typography>{t('Toolbar.Distance')}</Typography>
            </ToggleButton>
            <ToggleButton selected={currentMeasureMode === 'angle'} onChange={handleMeasureModeChange} value={'angle'}>
                <AdjustIcon />
                <Typography>{t('Toolbar.Angle')}</Typography>
            </ToggleButton>
        </Box>
    );
}

export default function Toolbar(props: ToolBarProps) {
    //General
    const [currentTool, setCurrentTool] = useState<Tools>('selection');
    const [isShowAll, setIsShowAll] = useState(false);
    const { t } = useTranslation('common');

    //Measure mode
    const [isMeasureActive, setIsMeasureActive] = useState(false);
    const measureMode = useState<'distance' | 'angle'>('distance');

    //Selection mode
    const [isSelectionActive, setIsSelectionActive] = useState(true);
    const selectionMode = useState<'entities' | 'elements'>('entities');
    const multiSelect = useState(false);

    const ToggleButton = styled(MuiToggleButton)({
        '&.Mui-selected, &.Mui-selected:hover': {
            color: 'rgba(0, 0, 0, 0.54)',
            backgroundColor: 'rgba(249,175,8,.4)',
        },
    });

    const handleToolChange = (event: React.MouseEvent<HTMLElement, MouseEvent>, value: Tools) => {
        let tool: Tools = value || 'selection';
        if (props.modelviewer) {
            switch (tool) {
                case 'selection':
                    props.modelviewer.setSelectionTool();
                    break;
                case 'measure':
                    props.modelviewer.setMeasureTool();
                    break;
                case 'section':
                    props.modelviewer.setSectionTool();
                    break;
                default:
                    props.modelviewer.setSelectionTool();
                    break;
            }
            setCurrentTool(tool);
            setIsMeasureActive(tool === 'measure');
            if (tool !== 'measure') {
                props.modelviewer._measureTool.ClearMeasurements();
            }
            setIsSelectionActive(tool === 'selection' || tool === 'none');
        }
    };

    useEffect(() => {
        if (props?.modelviewer) {
            props.modelviewer.ViewerEvents?.VisibilityEvents?.addListener('visibility', canShowAll);
        }

        return () => {
            // setCurrentTool('selection');
            props.modelviewer?.ViewerEvents?.VisibilityEvents?.removeListener('visibility', canShowAll);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.modelviewer]);

    useEffect(() => {
        if (props.modelviewer) {
            document.removeEventListener('keydown', handleKeyPress, false);
            document.addEventListener('keydown', handleKeyPress, false);
        }

        return () => {
            document.removeEventListener('keydown', handleKeyPress, false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.modelviewer]);

    const handleKeyPress = (event) => {
        // Only listen to key presses on model viewer canvas
        if (event.target?.firstChild?.nodeName !== 'CANVAS') {
            return;
        }

        switch (event.key.toLowerCase()) {
            case 'escape':
                handleToolChange(null, 'selection');
                break;
            case 'm':
                handleToolChange(null, 'measure');
                break;
            case 's':
                handleToolChange(null, 'section');
                break;
            case 'e':
                handleZoomExtent();
                break;
        }
    };

    const isObjectVisible = (object: any) => {
        if (!object.visible) return false;
        if (object.isInstancedMesh) {
            return object.count === object.instanceMatrix.array.length;
        }
        return true;
    };

    const canShowAll = () => {
        let objects = props.modelviewer?.currentScene?.getAllObjectForLayer(SceneLayerName.Entities);
        setIsShowAll(objects.some((obj) => !isObjectVisible(obj)));
    };

    const handleShowAll = () => {
        props.modelviewer.setVisibility(null, true);
        setIsShowAll(false);
    };

    const handleZoomExtent = () => {
        let entities = props.modelviewer.getEntities(true);
        props.modelviewer.cameraUtils.FocusCameraOnEntities(entities, { tween: true });
    };

    return (
        <Box className={styles['toolbar-container']} onKeyDown={handleKeyPress}>
            <Button aria-label="zoom tool" onClick={handleZoomExtent}>
                <ZoomOutMapIcon />
                <Typography className={styles['toolbar-button-label']}>{t('Toolbar.Zoom')}</Typography>
            </Button>
            <ToggleButtonGroup value={currentTool} exclusive classes={{ grouped: styles['grouped-button'] }}>
                <Box>
                    <ToggleButton
                        aria-label="selection tool"
                        value="selection"
                        className={isSelectionActive && styles['active']}
                        onClick={(e) => handleToolChange(e, 'selection')}>
                        <ExpandLessIcon className={styles['multi-level']} />
                        <SelectAllIcon />
                        <Typography className={styles['toolbar-button-label']}>{t('Toolbar.Selection')}</Typography>
                    </ToggleButton>
                    {props.modelviewer?._selectionTool &&
                        SelectionToolbarContent(
                            t,
                            handleToolChange,
                            props.modelviewer._selectionTool,
                            multiSelect,
                            selectionMode,
                            props.onSelectionModeToggle,
                            props.onMultiSelectToggle
                        )}
                </Box>
                <Box>
                    <ToggleButton
                        aria-label="measure tool"
                        value="measure"
                        className={isMeasureActive && styles['active']}
                        onClick={(e) => handleToolChange(e, 'measure')}>
                        <ExpandLessIcon className={styles['multi-level']} />
                        <SquareFootIcon />
                        <Typography className={styles['toolbar-button-label']}>{t('Toolbar.Measurement')}</Typography>
                    </ToggleButton>
                    {props.modelviewer?._measureTool &&
                        MeasureToolbarContent(t, handleToolChange, props.modelviewer._measureTool, measureMode)}
                </Box>
                <ToggleButton aria-label="section tool" value="section" onClick={(e) => handleToolChange(e, 'section')}>
                    <DomainDisabledIcon />
                    <Typography className={styles['toolbar-button-label']}>{t('Toolbar.Section')}</Typography>
                </ToggleButton>
            </ToggleButtonGroup>
            <Button className={styles['extra-button']} aria-label="show all tool" disabled={!isShowAll} onClick={handleShowAll}>
                <VisibilityIcon />
                <Typography className={styles['toolbar-button-label']}>{t('Toolbar.ShowAll')}</Typography>
            </Button>
        </Box>
    );
}

export function ContextualToolbar(props: ContextualToolbarProps) {
    const { t } = useTranslation('common');
    const [issue, setIssue] = useState(null);
    const [openImportSetDialog, setImportSetDialog] = useState(false);
    const [, setImportSetCreated] = useRecoilState(importSetCreatedAtom);
    const [importSetModelReferences, setImportSetModelReferences] = useState({});
    const { control } = useForm<any>({
        defaultValues: {
            files: null
        },
        mode: "onChange",
    });

    const Button = styled(MuiButton)({
        '&.Mui-selected, &.Mui-selected:hover': {
            color: 'rgba(0, 0, 0, 0.54)',
            backgroundColor: 'rgba(249,175,8,.4)',
        },
    });

    const InvisiblityIcon = () => {
        return <img src={process.env.PUBLIC_URL + '/icons/eye-closed.png'} alt="Icon for invisiblity" height={25} width={'auto'} />;
    };

    const IsolationIcon = () => {
        return (
            <img
                src={process.env.PUBLIC_URL + '/icons/compress-arrows-alt-solid.svg'}
                alt="Icon for isolation"
                height={20}
                width={'auto'}
            />
        );
    };

    const onImportSetCreatedHandler = (importSet: any) => {
        setImportSetCreated(importSet?._id);
    }

    const handleHide = () => {
        props.modelviewer.HideEntity(props.selection);
        props.onHideContextualMenu();
    };

    const handleIsolate = () => {
        props.modelviewer.isolateEntity(props.selection, true);
    };

    const handleCreateIssue = () => {
        setIssue({ modelReferences: JSON.stringify(getEntityReferences()) });
    };

    const handleCreateImportSet = () => {
        setImportSetDialog(true);
    };

    const handleIssueSubmit = (issues: any[] | null) => {
        setIssue(null);
        props.onAddIssues?.(issues);
        let viewer = props.modelviewer;
        if (!viewer)
            return;
        let refs: { [key: string]: { ifcid?: string; referenceId?: string }[] } = typeof issue.modelReferences === 'string' ? JSON.parse(issue.modelReferences) : issue.modelReferences;
        let entities = Object.entries(refs).reduce((accu: any[], [key, value]: any) => {
            let model = viewer?.project?.EntityTable[key];
            if (!model) return accu;
            let ents = value.map((ref) => model[ref.referenceId]).filter((e) => e);
            accu.push(...ents);
            return accu;
        }, []);
        viewer.HideEverything();
        viewer.ShowEntity(entities);
        viewer.cameraUtils.FocusCameraOnEntities(entities, { sides: Sides.Top | Sides.Left | Sides.Front });
        props.onHideContextualMenu();
    }

    const getEntityReferences = () => {
        if (!props.selection) {
            return null;
        }

        const references: Record<string, { referenceId: string, ifcId: string }[]> = {};

        for (const entity of props.selection) {
            const modelId = entity?.hsbmodel?.modelId;
            if (!modelId) continue;

            if (!references[modelId]) {
                references[modelId] = [];
            }

            references[modelId].push({
                referenceId: entity.handle,
                ifcId: entity.ifcid
            });
        }

        return Object.keys(references).length ? references : null;
    };

    const handleCloseImportSetDialog = () => {
        setImportSetDialog(false)
        setImportSetCreated(null);
    }

    useEffect(() => {
        if (!props.selection) {
            return;
        }
        setImportSetModelReferences(getModelReferences(props.selection));
    }, [props.selection])

    return (
        <Box
            className={styles['contextual-menu-container']}
            sx={{ top: props.contextualMenuSettings?.y, left: props.contextualMenuSettings?.x }}>
            <Button aria-label="hide tool" onClick={handleHide}>
                <InvisiblityIcon />
                <Typography className={styles['toolbar-button-label']}>{t('Toolbar.Hide')}</Typography>
            </Button>
            <Button aria-label="isolate tool" onClick={handleIsolate}>
                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} sx={{ width: '25px', height: '25px' }}>
                    <IsolationIcon />
                </Box>
                <Typography className={styles['toolbar-button-label']}>{t('Toolbar.Isolate')}</Typography>
            </Button>
            <Requires permissions={['issue.edit']} projectId={props.projectId}>
                <Button aria-label="issue tool" onClick={handleCreateIssue}>
                    <WarningIcon />
                    <Typography className={styles['toolbar-button-label']}>{t('Toolbar.Issue')}</Typography>
                </Button>
            </Requires>
            <Button aria-label="importset tool" onClick={handleCreateImportSet}>
                <FontAwesomeIcon icon={faCubesStacked} />
                <Typography className={styles['toolbar-button-label']}>{t('Toolbar.ImportSet')}</Typography>
            </Button>
            <FormInputFile multiple control={control} name='files' classes={{ container: `${styles['inline-input-wrapper']} ${styles['button']}`, "input-container": styles['inline-input-container'], "file-input-container": styles['file-input-container'] }} helperIcon={<LinkIcon />} helperText={t('Toolbar.Document')} onChange={(e) => props.handleLinkDocument(e)} />
            <IssueDialog
                issue={issue}
                projectId={props.projectId}
                onClose={() => setIssue(null)}
                onSubmit={handleIssueSubmit}
                screenCapture={props.modelviewer?.screenCapture}
                entityTable={props.modelviewer?.project.EntityTable}
            />
            {
                openImportSetDialog && <ImportSetDialog
                    modelReferences={importSetModelReferences}
                    onImportSetCreated={onImportSetCreatedHandler}
                    open={openImportSetDialog}
                    projectId={props.projectId}
                    onClose={handleCloseImportSetDialog}
                    viewer={props.modelviewer} />
            }
        </Box >
    );
}
