import ArchitectureIcon from '@mui/icons-material/Architecture'
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined'
import HomeIcon from '@mui/icons-material/Home'
import { Box, Paper, Stack } from '@mui/material'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'
import { grey } from '@mui/material/colors'
import { Indicator } from 'components/Shared/ButtonGroupIndicator/ButtonGroupIndicator'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import animMoveAtom from '../../atoms/animMoveAtom'

const defaultColor = grey[700]
const selectedColor = '#F6A81C'

const iconMap = {
    'home': { index: 0, path: '/' },
    'projects': { index: 1, path: '/projects' },
    'teams': { index: 2, path: '/teams' }
}

const setAndGetNewState = (iconColors: Array<string>, selectedIndex: number) => {
    return iconColors.map((color: string, i: number) => {
        if (i === selectedIndex) {
            return selectedColor
        }
        return defaultColor
    })
}

const getSelectedIndex = (iconColors: Array<string>): number => {
    return iconColors?.indexOf(selectedColor) || 0
}

const SideListItemText: React.FC<{ itemIndex: number, transKey: string, t: any, iconColors: Array<string>, children?: React.ReactNode }> = ({ children, itemIndex, transKey, t, iconColors }) => {
    return (
        <ListItemText >
            <div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', width: '6ch' }}>
                <Typography noWrap component={'div'} style={{ fontSize: '0.70rem', textAlign: 'center' }} variant="body2">
                    <span style={{ color: iconColors[itemIndex], maxWidth: 50, textAlign: 'center', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{t(transKey)}</span>
                </Typography>
            </div>
        </ListItemText>
    )
}

const getIconsStyle = (iconColors: Array<string>, itemIndex: number) => {
    return { color: iconColors[itemIndex] }
}

export default function SideNavBar() {
    const { component } = useParams<any>();
    const { t } = useTranslation('common');
    const navigate = useNavigate();
    const activePage = useRef(null);
    const homePage = useRef(null);
    const projectsPage = useRef(null);
    const teamsPage = useRef(null);
    const [iconColors, setIconColor] = useState([selectedColor, defaultColor, defaultColor]);
    const [animationMove, setAnimationMove] = useRecoilState(animMoveAtom);

    const setAnimMovAfterPathChange = (itemIndex: number) => {
        const aniMove = {
            fromIndex: (animationMove ? animationMove.toIndex : itemIndex),
            toIndex: itemIndex
        }
        setAnimationMove(aniMove)
    }

    useEffect(() => {
        activePage.current = homePage.current;
    }, [])

    useEffect(() => {
        // eslint-disable-next-line no-restricted-globals
        switch ('/' + component) {
            case iconMap.home.path: {
                setIconColor(setAndGetNewState(iconColors, iconMap.home.index));
                setAnimMovAfterPathChange(iconMap.home.index);
                activePage.current = homePage.current;
                break;
            }
            case iconMap.projects.path: {
                setIconColor(setAndGetNewState(iconColors, iconMap.projects.index));
                setAnimMovAfterPathChange(iconMap.projects.index);
                activePage.current = projectsPage.current;
                break;
            }
            case iconMap.teams.path: {
                setIconColor(setAndGetNewState(iconColors, iconMap.teams.index));
                setAnimMovAfterPathChange(iconMap.teams.index);
                activePage.current = teamsPage.current;
                break;
            }
            default: {
                setIconColor(setAndGetNewState(iconColors, iconMap.home.index));
                setAnimMovAfterPathChange(iconMap.home.index);
                activePage.current = homePage.current;
                break;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [component])

    const iconOnClickEvenHandler = (event: React.MouseEvent<SVGSVGElement | HTMLDivElement, MouseEvent>, index: number) => {
        event.preventDefault()
        activePage.current = event.currentTarget;
        const fromIndex = getSelectedIndex(iconColors)
        const newVal = setAndGetNewState(iconColors, index)
        const toIndex = getSelectedIndex(newVal)
        setAnimationMove({ fromIndex: fromIndex, toIndex: toIndex })
        setIconColor(newVal)
        const iconEntry = Object.values(iconMap).filter(entry => entry.index === index)
        navigate(iconEntry?.[0]?.path || iconMap.home.path);
    }

    return (
        <Box style={{ position: 'relative' }}>
            <Paper sx={{ paddingTop: '0', backgroundColor: '#FFFFFF', height: 'calc(100vh - 70px)', borderRadius: '0px', width: '75px' }} elevation={2}>
                <Stack sx={{ height: '100%' }}>
                    <List sx={{ flexGrow: 1 }}>
                        <ListItem ref={homePage} button key='sdhome' onClick={(e) => iconOnClickEvenHandler(e, iconMap.home.index)} style={{ paddingLeft: 0, paddingRight: 0, justifyContent: 'center' }} >
                            <ListItemIcon>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                    <HomeIcon fontSize="large" style={getIconsStyle(iconColors, iconMap.home.index)} />
                                    <SideListItemText t={t} itemIndex={iconMap.home.index} iconColors={iconColors} transKey={'SideNavBar.Home'} />
                                </div>
                            </ListItemIcon>
                        </ListItem>
                        <ListItem ref={projectsPage} button key='sdprojects' onClick={(e) => iconOnClickEvenHandler(e, iconMap.projects.index)} style={{ paddingLeft: 0, paddingRight: 0, justifyContent: 'center' }}>
                            <ListItemIcon >
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                    <ArchitectureIcon fontSize="large" style={getIconsStyle(iconColors, iconMap.projects.index)} />
                                    <SideListItemText t={t} itemIndex={iconMap.projects.index} iconColors={iconColors} transKey={'SideNavBar.Projects'} />
                                </div>
                            </ListItemIcon>
                        </ListItem>
                        <ListItem ref={teamsPage} button key='sdteams' onClick={(e) => iconOnClickEvenHandler(e, iconMap.teams.index)} style={{ paddingLeft: 0, paddingRight: 0, justifyContent: 'center' }}>
                            <ListItemIcon>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                    <GroupsOutlinedIcon fontSize="large" style={getIconsStyle(iconColors, iconMap.teams.index)} />
                                    <SideListItemText t={t} itemIndex={iconMap.teams.index} iconColors={iconColors} transKey={'SideNavBar.Teams'} />
                                </div>
                            </ListItemIcon>
                        </ListItem>
                    </List>
                    <Indicator anchor={activePage.current} />
                </Stack>
            </Paper>
        </Box>
    )
}