import { faTruckLoading } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { Indicator } from "components/Shared/ButtonGroupIndicator/ButtonGroupIndicator";
import NavBar from "components/Shared/NavBar";
import Tab from "components/Shared/Tab";
import TruckConfigurationDialog from "components/Stacking/Configuration/TruckConfigurationDialog";
import TruckConfigurationItem from "components/Stacking/Configuration/TruckConfigurationItem";
import { generateUUID } from "components/Stacking/StackingController";
import { SyntheticEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import styles from "./TruckConfigurationPage.module.scss";

export default function TruckConfigurationPage() {
    const { t } = useTranslation('common');
    const [openCreationDialog, setOpenCreationDialog] = useState(false);
    const [openConfigurationTab, setOpenConfigurationTab] = useState(true);
    const anchor = useRef(null);
    const buttonGroup = useRef(null);
    const { teamId } = useParams<any>();

    // TODO: remove dummy data
    const truckConfigurations = [
        { _id: generateUUID(), name: `Truck Config ${Math.floor(Math.random() * 100)}`, description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.', lastUpdatedOn: new Date(), state: 'open' },
        { _id: generateUUID(), name: `Truck Config ${Math.floor(Math.random() * 100)}`, description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.', lastUpdatedOn: new Date().setDate(new Date().getDate() - 1), state: 'active' },
        { _id: generateUUID(), name: `Truck Config ${Math.floor(Math.random() * 100)}`, description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.', lastUpdatedOn: new Date().setDate(new Date().getDate() - 2), state: 'in_progress' },
        { _id: generateUUID(), name: `Truck Config ${Math.floor(Math.random() * 100)}`, description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.', lastUpdatedOn: new Date().setDate(new Date().getDate() - 3), state: 'resolved' },
        { _id: generateUUID(), name: `Truck Config ${Math.floor(Math.random() * 100)}`, description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.', lastUpdatedOn: new Date().setDate(new Date().getDate() - 4), state: 'closed' },
        { _id: generateUUID(), name: `Truck Config ${Math.floor(Math.random() * 100)}`, description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.', lastUpdatedOn: new Date().setDate(new Date().getDate() - 5), state: 'due' },
    ]

    useEffect(() => {
        // TODO: Check if team ID exists and if user has permission to be here
    }, [teamId])


    const handleOpenCreationDialog = () => {
        setOpenCreationDialog(true);
    }

    const handleCloseCreationDialog = () => {
        setOpenCreationDialog(false);
    }

    const handleConfigurationTabToggle = () => {
        setOpenConfigurationTab(!openConfigurationTab);
    }

    const contentLayoutStyle = {
        display: 'grid',
        gridTemplateColumns: '75px ' + (openConfigurationTab ? '20vw' : '0') + ' 1fr 1fr 1fr 75px',
        gridTemplateRows: '100%',
        gridTemplateAreas: `"leftbar leftbar-content main main main rightbar-content rightbar"`,
        gap: 0,
        width: '100%',
        height: '100%',
    };

    const handleTab = (e: SyntheticEvent, value: string) => {
        let pressedButton: any = e.target;

        while (!pressedButton.classList.contains('MuiButtonBase-root') && pressedButton.parentElement) {
            pressedButton = pressedButton.parentElement;
        }

        if (anchor.current === pressedButton) {
            anchor.current = null;
        } else {
            anchor.current = pressedButton;
        }
    };

    return (
        <Box className={styles['content-layout']} height={'100%'}>
            <Box sx={{ gridArea: 'header' }}>
                <NavBar />
            </Box>
            <Box sx={{ gridArea: 'main' }} height={'100%'}>
                <Box {...contentLayoutStyle}>
                    <Box sx={{ gridArea: 'leftbar' }}>
                        <Box className={styles['sidebar-container']}>
                            <Tab open={openConfigurationTab} direction={'left'} title={t("ModelsPanel.Models")} onAddition={handleOpenCreationDialog}>
                                {
                                    truckConfigurations.map(truckConfig => {
                                        return <TruckConfigurationItem key={truckConfig._id} truckConfiguration={truckConfig} />
                                    })
                                }
                            </Tab>
                            <Box className={styles['button-container']}>
                                <Indicator anchor={anchor.current} />
                                <ToggleButtonGroup ref={buttonGroup} value={'trucks'} exclusive orientation="vertical" onChange={handleTab}>
                                    <ToggleButton
                                        id="trucks"
                                        value="trucks"
                                        classes={{ root: styles.button, selected: styles.buttonSelected }}
                                        onClick={handleConfigurationTabToggle}>
                                        <FontAwesomeIcon icon={faTruckLoading} />
                                        <Typography variant="caption" classes={{ root: styles.buttonText }}>
                                            {t('LeftNavBar.Console')}
                                        </Typography>
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Box>
                        </Box>
                    </Box>
                    <TruckConfigurationDialog open={openCreationDialog} onClose={handleCloseCreationDialog} />
                </Box>
            </Box>
        </Box>
    );
}