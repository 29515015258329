import { faBoxOpen, faGripHorizontal, faGripVertical, faTruckRampBox } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TabContext } from '@mui/lab';
import { Tab, Tabs } from '@mui/material';
import { Box } from '@mui/system';
import { Tools } from 'components/ModelViewer/Helpers/ViewerConfig';
import { ViewerCanvas } from 'components/ModelViewer/ViewerCanvas';
import TabPanel from 'components/Shared/TabPanel';
import { Viewer } from 'hsbshareviewer';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import PackageDetail from './Packages/PackageDetail.module';
import PackageList from './Packages/PackageList.module';
import styles from './StackingController.module.scss';
import { Package, Truck } from './Trucks/Truck';
import TruckDetail from './Trucks/TruckDetail.module';
import TruckList from './Trucks/TruckList.module';
import TruckViewer from './Viewer/TruckViewer';

function a11yProps(index: number) {
    return {
        id: `stacking-tab-${index}`,
        'aria-controls': `stacking-tabpanel-${index}`,
    };
}

// TODO: Remove test function
export function generateUUID(): string {
    const uuidTemplate = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';
    return uuidTemplate.replace(/[xy]/g, (c) => {
        const r = (Math.random() * 16) | 0;
        const v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}

const dummyTrucks = [
    {
        id: generateUUID(),
        name: `Truck ${Math.floor(Math.random() * 10000)}`,
        date: new Date(),
        location: { departure: { name: 'Amsterdam', date: new Date() }, destination: { name: 'London', date: new Date() } },
        layout: { id: generateUUID(), name: `Truck Layout ${Math.floor(Math.random() * 10)}` },
        items: [
            {
                id: 'f2e7c362-5ec2-40bc-9617-86546fbcafc1',
                type: 'package',
                name: 'Package-X01',
                items: [
                    { id: generateUUID(), name: 'Z-01' },
                    { id: generateUUID(), name: 'Z-02' },
                    { id: generateUUID(), name: 'Z-03' },
                    { id: generateUUID(), name: 'Z-04' },
                    { id: generateUUID(), name: 'Z-05' },
                    { id: generateUUID(), name: 'Z-06' },
                ],
            },
            { id: generateUUID(), name: 'E-01' },
            { id: generateUUID(), name: 'E-02' },
            { id: generateUUID(), name: 'E-201' },
            { id: generateUUID(), name: 'E-203' },
        ],
        attachments: Math.floor(Math.random() * 10),
        createdBy: { name: 'Nathan Collaert', avatar: { URI: null, letter: 'N', color: 'hsl(253.02857012995625,70%,68%)' } },
        details: {
            weight: Math.floor(Math.random() * 4000),
            length: Math.floor(Math.random() * 20),
            width: Math.floor(Math.random() * 10),
            height: Math.floor(Math.random() * 10),
            comments: 'This is a test comment',
        },
    },
    {
        id: generateUUID(),
        name: `Truck ${Math.floor(Math.random() * 10000)}`,
        date: new Date(new Date().getDate() - 1),
        location: { departure: { name: 'Amsterdam', date: new Date() }, destination: { name: 'London', date: new Date() } },
        layout: { id: generateUUID(), name: `Truck Layout ${Math.floor(Math.random() * 10)}` },
        items: [
            { id: generateUUID(), name: 'Z-01' },
            { id: generateUUID(), name: 'Z-02' },
            { id: generateUUID(), name: 'Z-03' },
            { id: generateUUID(), name: 'Z-04' },
            { id: generateUUID(), name: 'Z-05' },
            { id: generateUUID(), name: 'Z-06' },
        ],
        attachments: Math.floor(Math.random() * 10),
        createdBy: { name: 'Nathan Collaert', avatar: { URI: null, letter: 'N', color: 'hsl(253.02857012995625,70%,68%)' } },
        details: {
            weight: Math.floor(Math.random() * 4000),
            length: Math.floor(Math.random() * 20),
            width: Math.floor(Math.random() * 10),
            height: Math.floor(Math.random() * 10),
            comments: 'This is a test comment',
        },
    },
    {
        id: generateUUID(),
        name: `Truck ${Math.floor(Math.random() * 10000)}`,
        date: new Date(new Date().getDate() - 2),
        location: { departure: { name: 'Amsterdam', date: new Date() }, destination: { name: 'London', date: new Date() } },
        layout: { id: generateUUID(), name: `Truck Layout ${Math.floor(Math.random() * 10)}` },
        items: [
            { id: generateUUID(), name: 'Z-01' },
            { id: generateUUID(), name: 'Z-02' },
            { id: generateUUID(), name: 'Z-03' },
            { id: generateUUID(), name: 'Z-04' },
            { id: generateUUID(), name: 'Z-05' },
            { id: generateUUID(), name: 'Z-06' },
        ],
        attachments: Math.floor(Math.random() * 10),
        createdBy: { name: 'Nathan Collaert', avatar: { URI: null, letter: 'N', color: 'hsl(253.02857012995625,70%,68%)' } },
        details: {
            weight: Math.floor(Math.random() * 4000),
            length: Math.floor(Math.random() * 20),
            width: Math.floor(Math.random() * 10),
            height: Math.floor(Math.random() * 10),
            comments: 'This is a test comment',
        },
    },
    {
        id: generateUUID(),
        name: `Truck ${Math.floor(Math.random() * 10000)}`,
        date: new Date(),
        location: { departure: { name: 'Amsterdam', date: new Date() }, destination: { name: 'London', date: new Date() } },
        layout: { id: generateUUID(), name: `Truck Layout ${Math.floor(Math.random() * 10)}` },
        items: [
            { id: generateUUID(), name: 'Z-01' },
            { id: generateUUID(), name: 'Z-02' },
            { id: generateUUID(), name: 'Z-03' },
            { id: generateUUID(), name: 'Z-04' },
            { id: generateUUID(), name: 'Z-05' },
            { id: generateUUID(), name: 'Z-06' },
        ],
        attachments: Math.floor(Math.random() * 10),
        createdBy: { name: 'Nathan Collaert', avatar: { URI: null, letter: 'N', color: 'hsl(253.02857012995625,70%,68%)' } },
        details: {
            weight: Math.floor(Math.random() * 4000),
            length: Math.floor(Math.random() * 20),
            width: Math.floor(Math.random() * 10),
            height: Math.floor(Math.random() * 10),
            comments: 'This is a test comment',
        },
    },
    {
        id: generateUUID(),
        name: `Truck ${Math.floor(Math.random() * 10000)}`,
        date: new Date(),
        location: { departure: { name: 'Amsterdam', date: new Date() }, destination: { name: 'London', date: new Date() } },
        layout: { id: generateUUID(), name: `Truck Layout ${Math.floor(Math.random() * 10)}` },
        items: [
            { id: generateUUID(), name: 'Z-01' },
            { id: generateUUID(), name: 'Z-02' },
            { id: generateUUID(), name: 'Z-03' },
            { id: generateUUID(), name: 'Z-04' },
            { id: generateUUID(), name: 'Z-05' },
            { id: generateUUID(), name: 'Z-06' },
        ],
        attachments: Math.floor(Math.random() * 10),
        createdBy: { name: 'Nathan Collaert', avatar: { URI: null, letter: 'N', color: 'hsl(253.02857012995625,70%,68%)' } },
        details: {
            weight: Math.floor(Math.random() * 4000),
            length: Math.floor(Math.random() * 20),
            width: Math.floor(Math.random() * 10),
            height: Math.floor(Math.random() * 10),
            comments: 'This is a test comment',
        },
    },
    {
        id: generateUUID(),
        name: `Truck ${Math.floor(Math.random() * 10000)}`,
        date: new Date(),
        location: { departure: { name: 'Amsterdam', date: new Date() }, destination: { name: 'London', date: new Date() } },
        layout: { id: generateUUID(), name: `Truck Layout ${Math.floor(Math.random() * 10)}` },
        items: [
            { id: generateUUID(), name: 'Z-01' },
            { id: generateUUID(), name: 'Z-02' },
            { id: generateUUID(), name: 'Z-03' },
            { id: generateUUID(), name: 'Z-04' },
            { id: generateUUID(), name: 'Z-05' },
            { id: generateUUID(), name: 'Z-06' },
        ],
        attachments: Math.floor(Math.random() * 10),
        createdBy: { name: 'Nathan Collaert', avatar: { URI: null, letter: 'N', color: 'hsl(253.02857012995625,70%,68%)' } },
        details: {
            weight: Math.floor(Math.random() * 4000),
            length: Math.floor(Math.random() * 20),
            width: Math.floor(Math.random() * 10),
            height: Math.floor(Math.random() * 10),
            comments: 'This is a test comment',
        },
    },
    {
        id: generateUUID(),
        name: `Truck ${Math.floor(Math.random() * 10000)}`,
        date: new Date(),
        location: { departure: { name: 'Amsterdam', date: new Date() }, destination: { name: 'London', date: new Date() } },
        layout: { id: generateUUID(), name: `Truck Layout ${Math.floor(Math.random() * 10)}` },
        items: [
            { id: generateUUID(), name: 'Z-01' },
            { id: generateUUID(), name: 'Z-02' },
            { id: generateUUID(), name: 'Z-03' },
            { id: generateUUID(), name: 'Z-04' },
            { id: generateUUID(), name: 'Z-05' },
            { id: generateUUID(), name: 'Z-06' },
        ],
        attachments: Math.floor(Math.random() * 10),
        createdBy: { name: 'Nathan Collaert', avatar: { URI: null, letter: 'N', color: 'hsl(253.02857012995625,70%,68%)' } },
        details: {
            weight: Math.floor(Math.random() * 4000),
            length: Math.floor(Math.random() * 20),
            width: Math.floor(Math.random() * 10),
            height: Math.floor(Math.random() * 10),
            comments: 'This is a test comment',
        },
    },
    {
        id: generateUUID(),
        name: `Truck ${Math.floor(Math.random() * 10000)}`,
        date: new Date(),
        location: { departure: { name: 'Amsterdam', date: new Date() }, destination: { name: 'London', date: new Date() } },
        layout: { id: generateUUID(), name: `Truck Layout ${Math.floor(Math.random() * 10)}` },
        items: [
            { id: generateUUID(), name: 'Z-01' },
            { id: generateUUID(), name: 'Z-02' },
            { id: generateUUID(), name: 'Z-03' },
            { id: generateUUID(), name: 'Z-04' },
            { id: generateUUID(), name: 'Z-05' },
            { id: generateUUID(), name: 'Z-06' },
        ],
        attachments: Math.floor(Math.random() * 10),
        createdBy: { name: 'Nathan Collaert', avatar: { URI: null, letter: 'N', color: 'hsl(253.02857012995625,70%,68%)' } },
        details: {
            weight: Math.floor(Math.random() * 4000),
            length: Math.floor(Math.random() * 20),
            width: Math.floor(Math.random() * 10),
            height: Math.floor(Math.random() * 10),
            comments: 'This is a test comment',
        },
    },
    {
        id: generateUUID(),
        name: `Truck ${Math.floor(Math.random() * 10000)}`,
        date: new Date(),
        location: { departure: { name: 'Amsterdam', date: new Date() }, destination: { name: 'London', date: new Date() } },
        layout: { id: generateUUID(), name: `Truck Layout ${Math.floor(Math.random() * 10)}` },
        items: [
            { id: generateUUID(), name: 'Z-01' },
            { id: generateUUID(), name: 'Z-02' },
            { id: generateUUID(), name: 'Z-03' },
            { id: generateUUID(), name: 'Z-04' },
            { id: generateUUID(), name: 'Z-05' },
            { id: generateUUID(), name: 'Z-06' },
        ],
        attachments: Math.floor(Math.random() * 10),
        createdBy: { name: 'Nathan Collaert', avatar: { URI: null, letter: 'N', color: 'hsl(253.02857012995625,70%,68%)' } },
        details: {
            weight: Math.floor(Math.random() * 4000),
            length: Math.floor(Math.random() * 20),
            width: Math.floor(Math.random() * 10),
            height: Math.floor(Math.random() * 10),
            comments: 'This is a test comment',
        },
    },
];

function StackingController() {
    const { t } = useTranslation('common');
    const [tabValue, setTabValue] = useState('Packages');
    const [selectedPackage, setSelectedPackage] = useState(null);
    const [selectedTruck, setSelectedTruck] = useState(null);
    let resizerX: any = null;
    let resizerY: any = null;
    const viewerRef = React.useRef<Viewer>();
    const { id } = useParams();

    //TODO: Remove dummy data
    const [packages, setPackages] = useState<Array<Package>>([]);
    const [trucks, setTrucks] = useState<Array<Truck>>([]);

    useEffect(() => {
        setPackages([
            {
                id: 'f2e7c362-5ec2-40bc-9617-86546fbcafc1',
                name: `Package-X01`,
                date: new Date(),
                truck: `Truck ${Math.floor(Math.random() * 10000)}`,
                items: [
                    { id: generateUUID(), name: 'Z-01' },
                    { id: generateUUID(), name: 'Z-02' },
                    { id: generateUUID(), name: 'Z-03' },
                    { id: generateUUID(), name: 'Z-04' },
                    { id: generateUUID(), name: 'Z-05' },
                    { id: generateUUID(), name: 'Z-06' },
                ],
                attachments: Math.floor(Math.random() * 10),
                createdBy: { name: 'Nathan Collaert', avatar: { URI: null, letter: 'N', color: 'hsl(253.02857012995625,70%,68%)' } },
                details: {
                    weight: Math.floor(Math.random() * 1000),
                    length: Math.floor(Math.random() * 20),
                    width: Math.floor(Math.random() * 10),
                    height: Math.floor(Math.random() * 10),
                    comments: 'This is a test comment',
                },
            },
            {
                id: generateUUID(),
                name: `Package ${Math.floor(Math.random() * 10000)}`,
                date: new Date(new Date().getDate() - 1),
                truck: `Truck ${Math.floor(Math.random() * 10000)}`,
                items: [
                    { id: generateUUID(), name: 'Z-01' },
                    { id: generateUUID(), name: 'Z-02' },
                    { id: generateUUID(), name: 'Z-03' },
                    { id: generateUUID(), name: 'Z-04' },
                    { id: generateUUID(), name: 'Z-05' },
                    { id: generateUUID(), name: 'Z-06' },
                ],
                attachments: Math.floor(Math.random() * 10),
                createdBy: { name: 'Nathan Collaert', avatar: { URI: null, letter: 'N', color: 'hsl(253.02857012995625,70%,68%)' } },
                details: {
                    weight: Math.floor(Math.random() * 1000),
                    length: Math.floor(Math.random() * 20),
                    width: Math.floor(Math.random() * 10),
                    height: Math.floor(Math.random() * 10),
                    comments: 'This is a test comment',
                },
            },
            {
                id: generateUUID(),
                name: `Package ${Math.floor(Math.random() * 10000)}`,
                date: new Date(new Date().getDate() - 2),
                truck: `Truck ${Math.floor(Math.random() * 10000)}`,
                items: [
                    { id: generateUUID(), name: 'Z-01' },
                    { id: generateUUID(), name: 'Z-02' },
                    { id: generateUUID(), name: 'Z-03' },
                    { id: generateUUID(), name: 'Z-04' },
                    { id: generateUUID(), name: 'Z-05' },
                    { id: generateUUID(), name: 'Z-06' },
                ],
                attachments: Math.floor(Math.random() * 10),
                createdBy: { name: 'Nathan Collaert', avatar: { URI: null, letter: 'N', color: 'hsl(253.02857012995625,70%,68%)' } },
                details: {
                    weight: Math.floor(Math.random() * 1000),
                    length: Math.floor(Math.random() * 20),
                    width: Math.floor(Math.random() * 10),
                    height: Math.floor(Math.random() * 10),
                    comments: 'This is a test comment',
                },
            },
            {
                id: generateUUID(),
                name: `Package ${Math.floor(Math.random() * 10000)}`,
                date: new Date(),
                truck: `Truck ${Math.floor(Math.random() * 10000)}`,
                items: [
                    { id: generateUUID(), name: 'Z-01' },
                    { id: generateUUID(), name: 'Z-02' },
                    { id: generateUUID(), name: 'Z-03' },
                    { id: generateUUID(), name: 'Z-04' },
                    { id: generateUUID(), name: 'Z-05' },
                    { id: generateUUID(), name: 'Z-06' },
                ],
                attachments: Math.floor(Math.random() * 10),
                createdBy: { name: 'Nathan Collaert', avatar: { URI: null, letter: 'N', color: 'hsl(253.02857012995625,70%,68%)' } },
                details: {
                    weight: Math.floor(Math.random() * 1000),
                    length: Math.floor(Math.random() * 20),
                    width: Math.floor(Math.random() * 10),
                    height: Math.floor(Math.random() * 10),
                    comments: 'This is a test comment',
                },
            },
            {
                id: generateUUID(),
                name: `Package ${Math.floor(Math.random() * 10000)}`,
                date: new Date(),
                truck: `Truck ${Math.floor(Math.random() * 10000)}`,
                items: [
                    { id: generateUUID(), name: 'Z-01' },
                    { id: generateUUID(), name: 'Z-02' },
                    { id: generateUUID(), name: 'Z-03' },
                    { id: generateUUID(), name: 'Z-04' },
                    { id: generateUUID(), name: 'Z-05' },
                    { id: generateUUID(), name: 'Z-06' },
                ],
                attachments: Math.floor(Math.random() * 10),
                createdBy: { name: 'Nathan Collaert', avatar: { URI: null, letter: 'N', color: 'hsl(253.02857012995625,70%,68%)' } },
                details: {
                    weight: Math.floor(Math.random() * 1000),
                    length: Math.floor(Math.random() * 20),
                    width: Math.floor(Math.random() * 10),
                    height: Math.floor(Math.random() * 10),
                    comments: 'This is a test comment',
                },
            },
            {
                id: generateUUID(),
                name: `Package ${Math.floor(Math.random() * 10000)}`,
                date: new Date(),
                truck: `Truck ${Math.floor(Math.random() * 10000)}`,
                items: [
                    { id: generateUUID(), name: 'Z-01' },
                    { id: generateUUID(), name: 'Z-02' },
                    { id: generateUUID(), name: 'Z-03' },
                    { id: generateUUID(), name: 'Z-04' },
                    { id: generateUUID(), name: 'Z-05' },
                    { id: generateUUID(), name: 'Z-06' },
                ],
                attachments: Math.floor(Math.random() * 10),
                createdBy: { name: 'Nathan Collaert', avatar: { URI: null, letter: 'N', color: 'hsl(253.02857012995625,70%,68%)' } },
                details: {
                    weight: Math.floor(Math.random() * 1000),
                    length: Math.floor(Math.random() * 20),
                    width: Math.floor(Math.random() * 10),
                    height: Math.floor(Math.random() * 10),
                    comments: 'This is a test comment',
                },
            },
            {
                id: generateUUID(),
                name: `Package ${Math.floor(Math.random() * 10000)}`,
                date: new Date(),
                truck: `Truck ${Math.floor(Math.random() * 10000)}`,
                items: [
                    { id: generateUUID(), name: 'Z-01' },
                    { id: generateUUID(), name: 'Z-02' },
                    { id: generateUUID(), name: 'Z-03' },
                    { id: generateUUID(), name: 'Z-04' },
                    { id: generateUUID(), name: 'Z-05' },
                    { id: generateUUID(), name: 'Z-06' },
                ],
                attachments: Math.floor(Math.random() * 10),
                createdBy: { name: 'Nathan Collaert', avatar: { URI: null, letter: 'N', color: 'hsl(253.02857012995625,70%,68%)' } },
                details: {
                    weight: Math.floor(Math.random() * 1000),
                    length: Math.floor(Math.random() * 20),
                    width: Math.floor(Math.random() * 10),
                    height: Math.floor(Math.random() * 10),
                    comments: 'This is a test comment',
                },
            },
            {
                id: generateUUID(),
                name: `Package ${Math.floor(Math.random() * 10000)}`,
                date: new Date(),
                truck: `Truck ${Math.floor(Math.random() * 10000)}`,
                items: [
                    { id: generateUUID(), name: 'Z-01' },
                    { id: generateUUID(), name: 'Z-02' },
                    { id: generateUUID(), name: 'Z-03' },
                    { id: generateUUID(), name: 'Z-04' },
                    { id: generateUUID(), name: 'Z-05' },
                    { id: generateUUID(), name: 'Z-06' },
                ],
                attachments: Math.floor(Math.random() * 10),
                createdBy: { name: 'Nathan Collaert', avatar: { URI: null, letter: 'N', color: 'hsl(253.02857012995625,70%,68%)' } },
                details: {
                    weight: Math.floor(Math.random() * 1000),
                    length: Math.floor(Math.random() * 20),
                    width: Math.floor(Math.random() * 10),
                    height: Math.floor(Math.random() * 10),
                    comments: 'This is a test comment',
                },
            },
            {
                id: generateUUID(),
                name: `Package ${Math.floor(Math.random() * 10000)}`,
                date: new Date(),
                truck: `Truck ${Math.floor(Math.random() * 10000)}`,
                items: [
                    { id: generateUUID(), name: 'Z-01' },
                    { id: generateUUID(), name: 'Z-02' },
                    { id: generateUUID(), name: 'Z-03' },
                    { id: generateUUID(), name: 'Z-04' },
                    { id: generateUUID(), name: 'Z-05' },
                    { id: generateUUID(), name: 'Z-06' },
                ],
                attachments: Math.floor(Math.random() * 10),
                createdBy: { name: 'Nathan Collaert', avatar: { URI: null, letter: 'N', color: 'hsl(253.02857012995625,70%,68%)' } },
                details: {
                    weight: Math.floor(Math.random() * 1000),
                    length: Math.floor(Math.random() * 20),
                    width: Math.floor(Math.random() * 10),
                    height: Math.floor(Math.random() * 10),
                    comments: 'This is a test comment',
                },
            },
        ]);
        setTrucks(dummyTrucks);
        setSelectedTruck(dummyTrucks[0]);
    }, []);

    const handleResizeXMove = (e: any, touch?: boolean) => {
        const packageContainerWidth = document.getElementById('stacking-container').clientWidth;
        const clientX = touch ? e.touches[0].clientX : e.clientX;
        const deltaX = clientX - resizerX.offsetLeft;
        const l = resizerX.previousElementSibling;
        const r = resizerX.nextElementSibling;
        const MIN_WIDTH = 500;
        // Left
        if (deltaX < 0) {
            const lWidth = Math.round(parseInt(getComputedStyle(l).width) + deltaX);

            if (lWidth >= MIN_WIDTH) {
                l.style.flex = `0 ${(lWidth / packageContainerWidth) * 100}%`;
                r.style.flex = `1 0`;
            }
        }

        // Right
        if (deltaX > 0) {
            const rWidth = Math.round(parseInt(getComputedStyle(r).width) - deltaX);

            if (rWidth >= MIN_WIDTH) {
                r.style.flex = `0 ${(rWidth / packageContainerWidth) * 100}%`;
                l.style.flex = `1 0`;
            }
        }
    };

    const handleResizeYMove = (e: any, touch?: boolean) => {
        const packageContainerHeight = document.getElementById('stacking-container').clientHeight;
        const clientY = touch ? e.touches[0].clientY : e.clientY;
        const deltaY = clientY - resizerY.offsetTop;
        const t = resizerY.previousElementSibling;
        const b = resizerY.nextElementSibling;
        const MIN_HEIGHT = 215;

        // Up
        if (deltaY < 0) {
            const tHeight = Math.round(parseInt(getComputedStyle(t).height) + deltaY);

            if (tHeight >= MIN_HEIGHT) {
                t.style.flex = `0 ${(tHeight / packageContainerHeight) * 100}%`;
                b.style.flex = `1 0`;
            }
        }

        // Down
        if (deltaY > 0) {
            const bHeight = Math.round(parseInt(getComputedStyle(b).height) - deltaY);

            if (bHeight >= MIN_HEIGHT) {
                b.style.flex = `0 ${(bHeight / packageContainerHeight) * 100}%`;
                t.style.flex = `1 0`;
            }
        }
    };

    const handleMove = (e: any, touch?: boolean) => {
        if (resizerX) {
            handleResizeXMove(e, touch);
        } else if (resizerY) {
            handleResizeYMove(e, touch);
        }
    };

    const handleChange = (event: React.SyntheticEvent, newTabValue: string) => {
        setTabValue(newTabValue);
    };

    const handleSelectPackageById = (id: string) => {
        setSelectedPackage(packages.find((item) => item.id === id));
    };

    const handleSelectPackage = (packacgeObject: any) => {
        setSelectedPackage(packacgeObject);
    };

    const handleSelectTruck = (truck: any) => {
        setSelectedTruck(truck);
    };

    const handleTruckDialog = (e: any) => {
        throw new Error('Not Implemented');
    };

    const handlePackageDialog = (e: any) => {
        throw new Error('Not Implemented');
    };

    const handleCreatePackage = (packageContent: any[], truckId: string, navigate?: boolean) => {
        let truckB = trucks.find((truck) => truck.id === truckId);
        if (truckB) {
            // Create package and update data
            const packageObj: Package = {
                id: generateUUID(),
                name: `New Package (${packageContent.length})`,
                date: new Date(),
                truck: truckB.name,
                items: packageContent,
                attachments: Math.floor(Math.random() * 10),
                createdBy: { name: 'Nathan Collaert', avatar: { URI: null, letter: 'N', color: 'hsl(253.02857012995625,70%,68%)' } },
                details: {
                    weight: Math.floor(Math.random() * 1000),
                    length: Math.floor(Math.random() * 20),
                    width: Math.floor(Math.random() * 10),
                    height: Math.floor(Math.random() * 10),
                    comments: 'This is a test comment',
                },
            };
            const packagesCopy = [...packages];
            packagesCopy.push(packageObj);
            setPackages(packagesCopy);

            // Update truck items to include package and remove loose items
            const packageContentIds = packageContent.map((content) => content.id);
            truckB = { ...truckB, items: truckB.items.filter((item) => !packageContentIds.includes(item.id)) };
            truckB.items.push({ id: packageObj.id, type: 'package', name: packageObj.name, items: packageObj.items });
            setTrucks(trucks.map((truckA) => (truckA.id === truckB.id ? truckB : truckA)));

            // Update selected truck
            if (truckB.id === selectedTruck.id) {
                setSelectedTruck(truckB);
            }

            // Navigate to package
            navigate && setSelectedPackage(packageObj);
        }
    };

    const handleDeletePackage = (truckId: string, packageId: string) => {
        let truckB = trucks.find((truck) => truck.id === truckId);
        if (truckB) {
            truckB = { ...truckB, ...{ items: truckB.items.filter((value) => value.id !== packageId) } };
            setTrucks(trucks.map((truckA) => (truckA.id === truckB.id ? truckB : truckA)));

            // Update selected truck
            if (truckB.id === selectedTruck.id) {
                setSelectedTruck(truckB);
            }
        }
    };

    const handleEntityRemoval = (entities: { modelId: string; handle: string }[]) => {
        let entityTable = viewerRef.current?.project?.EntityTable;
        if (!entityTable) return;
        let ents = entities.map((ent) => entityTable[ent.modelId]?.[ent.handle]);
        ents = ents.filter((e) => !!e);
        ents.forEach((e) => {
            let obj = e.object as any;
            if (obj.userData) obj.userData.visible = true;
            if (obj.material) obj.material = obj.userData.originalmaterial;
        });
        viewerRef.current?.requestRenderFrame();
    };

    useEffect(() => {
        if (id)
            viewerRef.current
                ?.loadProject(id)
                .then((res) => {
                    viewerRef.current.setPickingTool();
                })
                .catch((err) => {
                    console.error(err);
                });

        return () => {};
    }, [id]);

    return (
        <Box
            id={'stacking-container'}
            className={styles['stacking-container']}
            onMouseMove={handleMove}
            onTouchMove={(e) => handleMove(e, true)}
            onPointerUp={() => {
                resizerX = null;
                resizerY = null;
            }}>
            <Box className={styles['resizable-x']}>
                <Box className={styles['viewer-container']}>
                    <TruckViewer
                        truck={selectedTruck}
                        projectViewer={viewerRef}
                        truckPackage={selectedPackage}
                        onTruckCreation={handleTruckDialog}
                        onPackageCreation={handlePackageDialog}
                        onEntityRemoval={handleEntityRemoval}
                    />
                </Box>
                <Box className={styles['resizer-x']} onPointerDown={(e: any) => (resizerX = e.currentTarget)}>
                    <FontAwesomeIcon icon={faGripVertical} />
                </Box>
                <Box className={styles['resizable-y']}>
                    <Box className={styles['right-side-top-container']}>
                        {!selectedPackage && !selectedTruck && (
                            <TabContext value={tabValue}>
                                <Box className={styles['tabs-wrapper']}>
                                    <Tabs
                                        value={tabValue}
                                        onChange={handleChange}
                                        aria-label="Stacking Tabs"
                                        classes={{
                                            indicator: styles['tab-indicator'],
                                            flexContainer: styles['tabs-container'],
                                            root: styles['tabs'],
                                        }}>
                                        <Tab
                                            icon={<FontAwesomeIcon icon={faBoxOpen} />}
                                            iconPosition="start"
                                            label={t('Stacking.Packages')}
                                            value={'Packages'}
                                            {...a11yProps(0)}
                                        />
                                        <Tab
                                            icon={<FontAwesomeIcon icon={faTruckRampBox} />}
                                            iconPosition="start"
                                            label={t('Stacking.Trucks')}
                                            value={'Trucks'}
                                            {...a11yProps(1)}
                                        />
                                    </Tabs>
                                </Box>
                                <TabPanel value={'Packages'} index={tabValue} className={styles['tab-panel']}>
                                    <PackageList packages={packages} onPackageRowClick={handleSelectPackage} />
                                </TabPanel>
                                <TabPanel value={'Trucks'} index={tabValue} className={styles['tab-panel']}>
                                    <TruckList trucks={trucks} onTruckRowClick={handleSelectTruck} />
                                </TabPanel>
                            </TabContext>
                        )}
                        {selectedPackage && <PackageDetail packageObj={selectedPackage} setSelectedPackage={setSelectedPackage} />}
                        {selectedTruck && (
                            <TruckDetail
                                truckObj={selectedTruck}
                                setSelectedTruck={setSelectedTruck}
                                onPackageSelection={handleSelectPackageById}
                                onPackageCreation={handleCreatePackage}
                                onPackageRemove={handleDeletePackage}
                            />
                        )}
                    </Box>
                    <Box className={styles['resizer-y']} onPointerDown={(e: any) => (resizerY = e.currentTarget)}>
                        <FontAwesomeIcon icon={faGripHorizontal} />
                    </Box>
                    <Box className={styles['viewer-container']}>
                        <ViewerCanvas id="viewer" ref={viewerRef} config={{ tools: Tools.Picker }} className={styles['viewer']} />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default StackingController;
