import CancelIcon from '@mui/icons-material/Cancel';
import { Box, IconButton, InputLabel } from "@mui/material";
import TextField from "@mui/material/TextField";
import { Controller } from "react-hook-form";
import styles from './FormInput.module.css';
import { FormInputTextProps } from "./FormInputProps";

export const FormInputText = ({
  maxRows,
  minRows,
  multiline,
  name,
  control,
  label,
  variant,
  rules,
  helpertext,
  defaultValue,
  sx,
  classes,
  className,
  errortext,
  endButton,
  disabled,
  onRemove,
  InputProps,
  onChange: propsOnChange,
  placeholder,
  onFocus,
  autoFocus
}: FormInputTextProps) => {
  return (
    <Box className={`${classes?.container} ${styles.textInput}`} id={'TextInput-' + name}>
      {
        label &&
        <InputLabel className={styles.label}>{label}</InputLabel>
      }
      <Box className={endButton && styles.textFieldWithEnd}>
        <Controller
          name={name}
          control={control}
          rules={rules}
          defaultValue={defaultValue}
          render={({
            field: { onChange, onBlur, value, ref },
            fieldState: { error }
          }) => (
            <TextField
              minRows={minRows}
              maxRows={maxRows}
              multiline={multiline}
              sx={sx}
              classes={{ ...{ root: endButton && styles.textFieldRootWithEnd }, ...classes }}
              className={`${className ?? ''} ${endButton ? styles.textFieldInputWithEnd : ''}`}
              helperText={error ? (errortext || error.message) : helpertext}
              disabled={disabled ?? false}
              size="small"
              error={!!error}
              onChange={(data) => { onChange(data); propsOnChange?.(data); }}
              onBlur={onBlur}
              value={value}
              fullWidth
              variant={variant || "outlined"}
              InputProps={{ ...InputProps, ...{ inputRef: ref, className: endButton && styles.textFieldInputWithEnd } }}
              placeholder={placeholder}
              onFocus={onFocus}
              autoFocus={autoFocus}
              id="textfield-form-control"
            />
          )}
        />
        {endButton}
      </Box>
      {
        onRemove &&
        <IconButton onClick={onRemove} classes={{ root: styles.removeButton }}>
          <CancelIcon classes={{ root: styles.removeIcon }} />
        </IconButton>
      }
    </Box >
  );
};