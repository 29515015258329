export default interface Config {
    dimensions?: { width: number; height: number };
    tools?: Tools;
}

export enum Tools {
    NONE = 0,
    Selection = 1 << 0,
    Measurement = 1 << 1,
    Section = 1 << 2,
    Transform = 1 << 3,
    Picker = 1 << 4,
    ALL = ~NONE,
}
