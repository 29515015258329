import { Avatar, Box, Chip } from '@mui/material';
import { grey } from '@mui/material/colors';
import SnackBarMessage, { ISnackBarData } from 'components/Shared/snackBar/SnackBarMessage';
import { HsbElement, HsbEntity, HsbProject, Viewer } from 'hsbshareviewer';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import config from 'services/config';
import styles from 'styles/ModelViewer.module.css';
import restAPI from '../../services/rest-api';
import { NodeData } from './ConsolePanel/NodeData';
import ViewerLoadingBar from './LoadingBar/LoadingBar';
import { Model, Revision } from './ModelsPanel/ModelsPanel';
import LeftSideBar from './SideBars/LeftSideBar';
import RightSideBar from './SideBars/RightSideBar';
import Toolbar, { ContextualToolbar } from './Toolbar/Toolbar';
import { ViewerCanvas } from './ViewerCanvas';
import ViewerSettings from './ViewerSettings/ViewerSettings';

export const shareModelDefinition = [
    {
        type: 'string',
        columnDef: 'modelName',
        readOnly: true,
        header: 'ShareModelList.Name',
    },
    {
        type: 'string',
        columnDef: 'revision',
        readOnly: false,
        header: 'ShareModelList.Revision',
    },
    {
        type: 'boolean',
        columnDef: 'active',
        readOnly: false,
        header: 'ShareModelList.Active',
    },
    {
        type: 'date',
        columnDef: 'createdAt',
        readOnly: true,
        header: "'ShareModelList.CreatedAt",
    },
    {
        type: 'string',
        columnDef: 'status',
        header: 'ShareModelList.Status',
        readOnly: false,
    },
];

export default function ModelViewer(props?: ModelViewerProps) {
    const { t } = useTranslation('common');
    const { id } = useParams<any>();
    const viewerRef = React.useRef<Viewer>();
    const [currentProject, setProject] = React.useState<any>(null);
    const currentProjectID = React.useRef<string>(null);
    const progress = React.useRef(0);
    const loadingMessage = React.useRef('Fetching Project');
    const [showLoadingBar, setShowLoadingBar] = useState(true);
    const [selection, setSelection] = React.useState<HsbEntity[] | null>();
    const selectionRef = React.useRef(selection);
    const [models, setModels] = useState<{ [key: string]: Model }>();
    const [issues, setIssues] = useState<{ [key: string]: any }[]>();
    const selectionMode = useRef<'elements' | 'entities'>('entities');
    const multiSelect = useRef<boolean>(false);
    const [showLoader, setShowLoader] = React.useState<boolean>(false);
    const [selectionChips, setSelectionChips] = React.useState<React.ReactFragment | null>(null);
    const [leftSidebarOpen, setLeftSidebarOpen] = useState(false);
    const [rightSidebarOpen, setRightSidebarOpen] = useState(false);
    const [isolatedHandle, setIsolatedHandle] = useState(null);
    const [contextualMenuSettings, setContextualMenuSettings] = useState({ canShow: false, x: 0, y: 0 });
    const [stateSnackBar, setSnackBar] = React.useState<ISnackBarData>({ open: false, severity: 'success', message: '' });
    const [folders, setFolders] = useState([]);
    const [copiedFile, setCopiedFile] = useState(null);
    const abortController = useRef<AbortController>();

    // #region useEffects

    useEffect(() => {
        let viewer = viewerRef.current;
        let mounted = true;

        // Init
        try {
            // Location
            if (!id) {
                if (viewer?.Active) {
                    viewer?.stop('viewer');
                    viewer?.disposeActiveScene();
                }
                setProject(null);
                currentProjectID.current = null;
                setShowLoader(true);
                return;
            }
            // Viewer
            if (viewer) {
                if (showLoader) {
                    console.log('Already active');
                }
                setShowLoader(true);
                progress.current = 1;
                loadingMessage.current = 'Fetching project';
                setShowLoadingBar(true);
                abortController.current = new AbortController();
                loadProject(id, { signal: abortController.current.signal })
                    .then((res) => {
                        if (!mounted) return;
                        switch (res.status) {
                            case 200:
                                loadItemData(res.data);
                                setProject(res.data);
                                break;
                            case 204:
                                setProject(res.data);
                                setSnackBar({ open: true, severity: 'info', message: 'This project does not contain any model' });
                                break;
                            default:
                                setSnackBar({
                                    open: true,
                                    severity: 'error',
                                    message: res.message ?? 'There was a problem loading project ' + id,
                                });
                                break;
                        }
                    })
                    .catch((err) => {
                        if (window.location.hostname === 'localhost') {
                            console.error(err);
                        }
                        if (err !== 'Cleanup')
                            setSnackBar({
                                open: true,
                                severity: 'error',
                                message: err.message ?? 'There was a problem loading project ' + id,
                            });
                    })
                    .finally(() => {
                        setShowLoadingBar(false);
                    });
            }
        } catch (error) {
            console.error('Something went wrong while mounting the Modelviewer.');
            if (window.location.hostname === 'localhost') {
                console.error(error);
            }
        }

        return () => {
            abortController.current?.abort('Cleanup');
            mounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        if (currentProject) document.title = `${currentProject.projectItem.name} | hsbShare`;
    }, [currentProject]);

    useEffect(() => {
        let mounted = true;
        if (currentProjectID.current) {
            restAPI
                .getDocuments(currentProjectID.current)
                .then((result) => {
                    if (mounted) {
                        setFolders(result);
                    }
                })
                .catch((err) => {
                    console.error(err);
                });
        }
        return () => {
            mounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentProjectID.current]);

    const FilterSelectionOnType = useCallback((type: string, remove: boolean) => {
        if (!viewerRef.current) return;
        let newSelection = selectionRef.current.filter((e) => (e.type === type) !== remove);
        if (remove) {
            let filtered = selectionRef.current.filter((e) => e.type === type);
            let elements = [],
                entities = [];
            filtered.forEach((e) => {
                e instanceof HsbElement ? elements.push(e) : entities.push(e);
            });
            newSelection = newSelection
                .filter((e) => elements.findIndex((ele) => ele.handle === e.getElement()?.handle) === -1)
                .filter((e) => entities.findIndex((ent) => ent.getElement()?.handle === e.handle) === -1);
        } else {
            let filtered = newSelection.filter((e) => e instanceof HsbElement);
            let children = selectionRef.current.filter(
                (eSelection) => filtered.findIndex((e) => e.handle === eSelection.getElement()?.handle) > -1
            );
            newSelection = newSelection.concat(children);
        }
        viewerRef.current.setSelection(newSelection, true, true);
        setSelection(newSelection);
        setSelectionChips(EntityChips(newSelection));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // #endregion
    const EntityChips = useCallback(
        (entities: HsbEntity[]): React.ReactFragment => {
            if (!entities?.filter) return null;
            let types = entities.filter((e) => e.type !== 'NONHSB').map((e) => e.type);
            let elementTypes = entities.filter((e: HsbEntity) => e instanceof HsbElement && e.type !== 'NONHSB').map((e) => e.type);
            types = types.sort((a, b) => elementTypes.findIndex((x) => x === b) - elementTypes.findIndex((x) => x === a));

            const counts = {};
            types.forEach((type) => {
                counts[type] = (counts[type] || 0) + 1;
            });
            return Object.entries(counts).map(([type, nr]: any) => {
                return (
                    <Chip
                        className={elementTypes.includes(type) ? styles.chipElement : styles.chipEntity}
                        key={type}
                        avatar={<Avatar className={styles.chipCounter}>{nr}</Avatar>}
                        label={type}
                        onClick={() => {
                            FilterSelectionOnType(type, false);
                        }}
                        onDelete={() => {
                            FilterSelectionOnType(type, true);
                        }}
                    />
                );
            });
        },
        [FilterSelectionOnType]
    );
    // #region modelviewer logic
    const onSelection = useCallback(
        (data: any) => {
            if (!data) {
                return;
            }

            setSelection(data.entities);
            setSelectionChips(EntityChips(data.entities));
            selectionRef.current = data.entities;
            if (data.entities?.length > 0) {
                setContextualMenuSettings({ canShow: true, x: data.event.offsetX, y: data.event.offsetY });
            }
        },
        [EntityChips]
    );

    const onVisibilityToggle = (entities: any) => {
        viewerRef.current.setVisibility(null, false);
        viewerRef.current.ShowEntity(entities);
    };

    const loadModelData = (modelNodes) => {
        let modelObjects: any = {};
        modelNodes.forEach((node: any) => {
            let modelmetadata: Revision = Object.fromEntries(
                Object.entries(node.metadata.data.properties).map(([key, val]: [any, any]) => {
                    return [key, val.value];
                })
            );
            modelmetadata.node = node;
            modelmetadata.createdAt = node.metadata.data.createdAt && new Date(node.metadata.data.createdAt);
            modelmetadata.updatedAt = node.metadata.data.updatedAt && new Date(node.metadata.data.updatedAt);
            modelmetadata.projectId = node.metadata.data.projectId;
            let modelDrawing: Model = modelObjects[modelmetadata.modelName];
            if (!modelDrawing) {
                modelDrawing = modelObjects[modelmetadata.modelName] = {
                    source: node.projectSource,
                    unit: node.Units?._unit,
                    createdAt: modelmetadata.createdAt && new Date(modelmetadata.createdAt),
                    updatedAt: modelmetadata.updatedAt && new Date(modelmetadata.updatedAt),
                    createdBy: modelmetadata.createdBy,
                    activeRevision: null,
                    revisions: [],
                    status: modelmetadata.status,
                };
            }
            if (modelmetadata.active) {
                if (modelDrawing.activeRevision) {
                    modelDrawing.activeRevision.active = false;
                }
                modelDrawing.activeRevision = modelmetadata;
                modelDrawing.revisions.unshift(modelmetadata);
            } else {
                modelDrawing.revisions.push(modelmetadata);
            }
            let revCDate = new Date(modelmetadata.createdAt);
            if (modelDrawing.createdAt > revCDate) {
                modelDrawing.createdAt = revCDate;
            }
            let revUDate = new Date(modelmetadata.updatedAt);
            if (modelDrawing.updatedAt > revUDate) {
                modelDrawing.updatedAt = revUDate;
            }
        });
        // Sort revisions by updatedAt
        Object.values(modelObjects).forEach((value: any) => {
            value.revisions.sort((a, b) => {
                let activeRev = b.active - a.active;
                if (activeRev === 0) {
                    return new Date(b.updatedAt).valueOf() - new Date(a.updatedAt).valueOf();
                } else {
                    return activeRev;
                }
            });
        });
        return modelObjects;
    };

    const loadIssueData = (data: any) => {
        return data
            .map((docNode) => {
                return docNode.metadata.data;
            })
            .sort((a, b) => {
                return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
            });
    };

    const loadItemData = (project: any) => {
        let hsbMakeModels = project.nodeModel.getNodesMetadataIdByItemType(project.nodeModel.ItemsRoot, 'hsbshare.projectmodel');
        setModels(loadModelData(hsbMakeModels));
        let hsbmakeIssues = project.nodeModel.getNodesMetadataIdByItemType(project.nodeModel.ItemsRoot, 'hsbshare.issue');
        setIssues(loadIssueData(hsbmakeIssues));
    };

    const loadProject = async (
        inProject: string | HsbProject,
        options?: Partial<{ forceFetch: boolean; signal: AbortSignal }>
    ): Promise<any> => {
        return viewerRef.current?.loadProject(inProject, options);
    };

    const handleModelChanges = () => {
        loadProject(id, { forceFetch: true, signal: viewerRef.current.abortController.signal })
            .then((res) => {
                switch (res.status) {
                    case 200:
                        loadItemData(res.data);
                        setProject(res.data);
                        break;
                    case 204:
                        loadItemData(res.data);
                        setProject(res.data);
                        setShowLoadingBar(false);
                        break;
                    default:
                        setSnackBar({ open: true, severity: 'error', message: res.message ?? 'There was a problem loading project ' + id });
                        break;
                }
            })
            .catch((err) => {
                if (window.location.hostname === 'localhost') console.error(err);
                setShowLoadingBar(false);
                setSnackBar({ open: true, severity: 'error', message: err.message ?? 'There was a problem loading project ' + id });
            });
    };

    const handleSelectionModeToggle = (mode: 'elements' | 'entities') => {
        selectionMode.current = mode;
    };

    const handleMultiSelectToggle = (state: boolean) => {
        multiSelect.current = state;
    };

    const handleChangeSideBar = (leftSidebarOpen: boolean, rightSidebarOpen: boolean) => {
        if (leftSidebarOpen !== null) {
            setLeftSidebarOpen(leftSidebarOpen);
        }
        if (rightSidebarOpen !== null) {
            setRightSidebarOpen(rightSidebarOpen);
        }
    };

    const handleCameraMoveStart = () => {
        if (contextualMenuSettings.canShow) {
            return;
        }

        handleHideContextualMenu();
    };

    const handleHideContextualMenu = () => {
        const contextualMenuSettingsCopy = Object.assign({}, contextualMenuSettings);
        contextualMenuSettingsCopy.canShow = false;
        setContextualMenuSettings(contextualMenuSettingsCopy);
    };

    const onIsolation = (node: NodeData, isolated: boolean) => {
        if (isolated) {
            viewerRef.current._selectionTool.IsolateEntities(node.getAllEntities());
        } else {
            viewerRef.current.setSelection(null);
            viewerRef.current.setVisibility(null, true);
        }
        setIsolatedHandle(isolated ? node.dbId : null);
    };
    // #endregion

    // #region issues logic
    const handleAddIssues = (newIssues: any[]) => {
        setIssues(issues.concat(newIssues));
    };

    const handleDeleteIssues = (issueId: any) => {
        setIssues(issues.filter((i) => i._id !== issueId));
    };
    // #endregion

    // #region documents logic
    const handleAddFile = (e: any, selectedFolder: any) => {
        const dt = new DataTransfer();
        e.forEach((file: any) => dt.items.add(file));
        addDocumentsToFolder(selectedFolder, dt, (result) => {
            const foldersCopy = Array.from(folders);
            Object.assign(
                foldersCopy.find((folder) => folder._id === result._id),
                result
            );
            setFolders(foldersCopy);
        });
    };

    const handleLinkDocument = (e: any) => {
        const selectionReferences = getSelectionReferences();
        const folder = folders.find((folder) => {
            return areObjectsEqual(selectionReferences, folder.modelReferences);
        });

        const dt = new DataTransfer();
        e.forEach((file: any) => dt.items.add(file));

        if (folder) {
            // Upload to existing folder
            addDocumentsToFolder(folder, dt, (result) => {
                setFolders(
                    folders.map((folder) => {
                        return folder._id === result._id ? result : folder;
                    })
                );
            });
        } else {
            // Upload to new folder
            restAPI
                .addFolder(currentProjectID.current, 'New Folder', JSON.stringify(selectionReferences))
                .then((folderResult) => {
                    addDocumentsToFolder(folderResult, dt, (result) => {
                        setFolders([...folders, ...[result]]);
                    });
                })
                .catch((error) => {
                    setSnackBar({ open: true, message: t('Toolbar.ErrorUploadingFiles'), severity: 'error' });
                });
        }
    };

    const addDocumentsToFolder = (folder: any, dt: any, callback: any) => {
        restAPI
            .uploadDocumentsToNode(folder._id, dt.files, (progressEvent: any) => {
                setSnackBar({
                    open: true,
                    message:
                        dt.items.length > 1 ? t('Toolbar.UploadFilesProgress', { amount: dt.items.length }) : dt.items[0].getAsFile().name,
                    progress: Math.round((progressEvent.loaded * 100) / progressEvent.total),
                    severity: 'info',
                });
            })
            .then((result) => {
                callback(result);
                setSnackBar({
                    open: true,
                    message: dt.items.length > 1 ? t('Toolbar.UploadFilesSucces') : t('Toolbar.UploadFileSucces'),
                    severity: 'success',
                });
            })
            .catch((error) => {
                setSnackBar({
                    open: true,
                    message:
                        error.status === 413
                            ? t('Toolbar.ErrorMaxFileSizeExceeded', { maxFileSize: `${error.maxFileSize / (1024 * 1024)} MB` })
                            : t('Toolbar.ErrorUploadingFiles'),
                    severity: 'error',
                });
            });
    };

    const areObjectsEqual = (obj1, obj2) => {
        // Check if both objects are null
        if (!obj1 && !obj2) {
            return true;
        }

        // Check if only one object is null
        if (!obj1 || !obj2) {
            return false;
        }

        // Check if number of keys is the same
        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);
        if (keys1.length !== keys2.length) {
            return false;
        }

        // Compare the values of each key
        for (let key of keys1) {
            if (!obj2.hasOwnProperty(key)) {
                return false;
            }

            const value1 = obj1[key];
            const value2 = obj2[key];
            if (!arraysOfObjectsAreEqual(value1, value2)) {
                return false;
            }
        }

        // All keys and values match
        return true;
    };

    // Helper function to compare arrays of objects
    const arraysOfObjectsAreEqual = (arr1, arr2) => {
        if (arr1.length !== arr2.length) {
            return false;
        }

        for (let i = 0; i < arr1.length; i++) {
            const obj1 = arr1[i];
            const obj2 = arr2[i];

            if (!objectsAreEqual(obj1, obj2)) {
                return false;
            }
        }

        return true;
    };

    // Helper function to compare objects
    function objectsAreEqual(obj1, obj2) {
        // Check if both objects are null
        if (obj1 === null && obj2 === null) {
            return true;
        }

        // Check if only one object is null
        if (obj1 === null || obj2 === null) {
            return false;
        }

        // Compare referenceId and ifcId properties
        return obj1.referenceId === obj2.referenceId && obj1.ifcId === obj2.ifcId;
    }
    // #endregion

    const getSelectionReferences = () => {
        const references = {};
        selection.forEach((entity) => {
            const modelId = entity.hsbmodel.modelId;
            if (references.hasOwnProperty(modelId)) {
                references[modelId].push({
                    referenceId: entity.handle,
                    ifcId: entity.ifcid,
                });
            } else {
                references[modelId] = [
                    {
                        referenceId: entity.handle,
                        ifcId: entity.ifcid,
                    },
                ];
            }
        });
        return references;
    };

    useEffect(() => {
        let viewer = viewerRef.current;
        if (viewer) {
            viewer.loadingProgress.removeAllListeners();
            viewer.loadingProgress.onComplete(() => {
                setShowLoadingBar(false);
                viewer.setSelectionTool();
            });
            viewer.ViewerEvents.ToolEvents.addListener('selection', onSelection);
            viewer.ViewerEvents.CameraEvent.addListener('start', handleCameraMoveStart);
        }
        return () => {
            viewer?.loadingProgress.removeAllListeners();
            viewer?.ViewerEvents?.ToolEvents.removeListener('selection', onSelection);
            viewer?.ViewerEvents?.CameraEvent.removeListener('start', handleCameraMoveStart);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewerRef.current]);

    const contentLayoutStyle = {
        display: 'grid',
        gridTemplateColumns: '75px ' + (leftSidebarOpen ? '20vw' : '0') + ' 1fr 1fr 1fr ' + (rightSidebarOpen ? '20vw' : '0') + ' 75px',
        gridTemplateRows: '100%',
        gridTemplateAreas: `"leftbar leftbar-content main main main rightbar-content rightbar"`,
        gap: 0,
        width: '100%',
        height: '100%',
    };

    return (
        <Box {...contentLayoutStyle}>
            {id && (
                <Box id={'ModelViewerContainer'} className={styles.component} style={{ gridArea: 'main' }}>
                    <Box className={styles.container} sx={{ color: grey[700] }}>
                        <ViewerCanvas id="viewer" ref={viewerRef} className={styles.modelviewer}>
                            {!showLoadingBar && (
                                <Toolbar
                                    modelviewer={viewerRef.current}
                                    onSelectionModeToggle={handleSelectionModeToggle}
                                    onMultiSelectToggle={handleMultiSelectToggle}
                                    project={currentProject}
                                />
                            )}
                            {config.development && !showLoadingBar && (
                                <ViewerSettings projectID={currentProjectID.current} viewerRef={viewerRef.current} />
                            )}
                            {contextualMenuSettings?.canShow && (
                                <ContextualToolbar
                                    contextualMenuSettings={contextualMenuSettings}
                                    projectId={currentProjectID.current}
                                    selection={selection}
                                    modelviewer={viewerRef.current}
                                    selectionReferences={getSelectionReferences()}
                                    onHideContextualMenu={handleHideContextualMenu}
                                    onAddIssues={handleAddIssues}
                                    handleLinkDocument={handleLinkDocument}
                                />
                            )}
                        </ViewerCanvas>
                    </Box>
                    <ViewerLoadingBar
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        open={showLoadingBar}
                        progress={viewerRef.current?.loadingProgress}
                        message={loadingMessage.current}
                    />
                    <SnackBarMessage
                        severity={stateSnackBar.severity}
                        message={stateSnackBar.message}
                        open={stateSnackBar.open}
                        onSetOpen={setSnackBar}
                        progress={stateSnackBar.progress}
                    />
                    {selection && <Box className={styles.selectionChips}>{selectionChips}</Box>}
                </Box>
            )}
            <Box sx={{ gridArea: 'rightbar' }}>
                <RightSideBar
                    onSidebarChange={handleChangeSideBar}
                    handleDeleteIssue={handleDeleteIssues}
                    issues={issues}
                    selection={selection}
                    selectionMode={selectionMode.current}
                    multiSelect={multiSelect.current}
                    projectId={id}
                    isLoading={showLoadingBar}
                    modelviewer={viewerRef.current}
                />
            </Box>
            <Box sx={{ gridArea: 'leftbar' }}>
                <LeftSideBar
                    onSidebarChange={handleChangeSideBar}
                    models={models}
                    projectNode={currentProject}
                    modelviewer={viewerRef.current}
                    selection={selection}
                    projectId={currentProjectID.current}
                    onModelAdded={handleModelChanges}
                    onModelRemoved={handleModelChanges}
                    onVisibilityToggle={onVisibilityToggle}
                    onIsolation={onIsolation}
                    isolatedHandle={isolatedHandle}
                    isLoading={showLoadingBar}
                    folders={folders}
                    setFolders={setFolders}
                    copiedFile={copiedFile}
                    setCopiedFile={setCopiedFile}
                    handleAddFile={handleAddFile}
                />
            </Box>
        </Box>
    );
}

export interface ModelViewerProps {
    projectID?: string;
    width?: number;
    height?: number;
    setViewer?: Dispatch<SetStateAction<Viewer>>;
}
