import { Box, Button, Typography } from '@mui/material';
import { Tools } from 'components/ModelViewer/Helpers/ViewerConfig';
import { ViewerCanvas } from 'components/ModelViewer/ViewerCanvas';
import { Viewer } from 'hsbshareviewer';
import { useEffect, useRef } from 'react';
import { Package, Truck } from '../Trucks/Truck';
import styles from './Truckviewer.module.scss';

const dummyDataIds = {
    truck: '649aa27c0a7922d4c4a20af7',
    config: '649aa2540bdda27eb05f3ad3',
    cabin: '649aa23835dfa88234822394',
    trailer: '649aa21f8ee1f660f4a911dc',
};

function TruckViewer(props: TruckViewerProps) {
    const { projectViewer, truckPackage, truck, onTruckCreation, onPackageCreation, onEntityRemoval } = props;
    const viewer = useRef<Viewer>();

    useEffect(() => {
        let target;
        let currViewer = viewer.current;
        if (truck) target = viewer.current.StackingManager.addTrailer(truck);
        if (truckPackage) target = viewer.current.StackingManager.addPackage(truckPackage);
        return () => {
            if (target) currViewer?.SceneManager.RemoveFromActiveScene(target.mesh);
        };
    }, [truck, truckPackage]);

    useEffect(() => {
        if (projectViewer.current) {
            projectViewer.current.ViewerEvents.ToolEvents.addListener('picked', (e) => {
                viewer.current.StackingManager.addEntity(e.entity);
            });
        }
    }, [projectViewer]);

    useEffect(() => {
        let currentViewer = viewer.current;
        if (currentViewer) {
            currentViewer.setTransformTool();
            currentViewer.ViewerEvents.ToolEvents.addListener('stacking.removed', (event) => {
                onEntityRemoval(event.entities);
            });
            currentViewer.StackingManager.active = true;
        }
        return () => {
            if (currentViewer) {
                currentViewer.StackingManager.active = false;
            }
        };
    }, [viewer]);

    return (
        <Box id="TruckViewerContainer" className={styles.container}>
            <ViewerCanvas id="TruckViewer" ref={viewer} config={{ tools: Tools.Transform }}></ViewerCanvas>
            {!truck && !truckPackage && (
                <Box className={styles['overlay']}>
                    <Box className={styles['actions']}>
                        <Button variant="outlined" onClick={onTruckCreation}>
                            {'Create a package'}
                        </Button>
                        <Button variant="outlined" onClick={onPackageCreation}>
                            {'Create a truck'}
                        </Button>
                    </Box>
                    <Typography variant="caption">{'or select a package/truck to the right...'}</Typography>
                </Box>
            )}
        </Box>
    );
}

export interface TruckViewerProps {
    truck?: Truck;
    truckPackage?: Package;
    projectViewer: React.MutableRefObject<Viewer>;
    onTruckCreation: (e) => void;
    onPackageCreation: (e) => void;
    onEntityRemoval: (entities: { modelId: string; handle: string }[]) => void;
}

export default TruckViewer;
