const dateUtils = {
    formatDate: (date: Date) => {
        return date?.toLocaleTimeString([], { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' }).replace('.', '');
    },
    formatDetailedDate: (date: Date) => {
        // Ensure the input is a Date object
        if (!(date instanceof Date)) {
            date = new Date(date);
        }

        // Get the current and yesterday's date
        const currentDate = new Date(),
            yesterday = new Date(currentDate);
        yesterday.setDate(currentDate.getDate() - 1);

        // Check if the date is today
        if (date.getDate() === currentDate.getDate() && date.getMonth() === currentDate.getMonth() && date.getFullYear() === currentDate.getFullYear()) {
            // Format time as "h:mm AM/PM"
            const formattedTime = date.toLocaleString('en-US', {
                hour: 'numeric',
                minute: '2-digit',
                hour12: true,
            });

            return `Today at ${formattedTime}`;
        }
        // Check if the date is yesterday
        else if (date.getDate() === yesterday.getDate() && date.getMonth() === yesterday.getMonth() && date.getFullYear() === yesterday.getFullYear()) {
            // Format time as "h:mm AM/PM"
            const formattedTime = date.toLocaleString('en-US', {
                hour: 'numeric',
                minute: '2-digit',
                hour12: true,
            });

            return `Yesterday at ${formattedTime}`;
        } else {
            // Format date as "DD/MM/YYYY at h:mm AM/PM"
            const formattedDate = date.toLocaleString('en-US', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: 'numeric',
                minute: '2-digit',
                hour12: true,
            });

            return formattedDate;
        }
    }
}

export default dateUtils;