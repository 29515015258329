import { Close } from '@mui/icons-material';
import { Button, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { States } from 'components/ModelViewer/Issues/IssueDialog';
import DialogBox from 'components/Shared/DialogBox';
import { FormInputSelect } from 'components/Shared/FormComponents/FormInputSelect/FormInputSelect';
import { FormInputSelectMenuItem } from 'components/Shared/FormComponents/FormInputSelect/FormInputSelectMenuItem';
import { FormInputText } from 'components/Shared/FormComponents/FormInputText';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import propertiesStyles from '../../../styles/Properties.module.scss';
import styles from './TruckConfigurationDialog.module.scss';

export enum TrailerTypes {
    LOWBOY = 'Lowboy',
    FLATBED = 'Flatbed',
    EXTENDABLE_FLATBED = 'ExtendableFlatbed',
    EXTENDABLE_DOUBLE_DROP = 'ExtendableDoubleDrop',
    STEP_DECK = 'StepDeck',
    ROLL = 'Roll',
    HOTSHOT = 'Hotshot',
    DOUBLE_DROP = 'DoubleDrop'
}

function TruckConfigurationDialog(props: TruckConfigurationDialogProps) {
    const { t } = useTranslation('common');
    const { open, onClose, onSubmit } = props;
    const { control, formState } = useForm<any>({
        defaultValues: {
            name: "",
            state: null,
            length: "",
            width: "",
            depth: "",
            height: "",
            type: null,
            description: ""
        },
        mode: "onChange",
    });

    const handleClose = (e: any) => {
        onClose?.(e);
    }

    const getStateOptions = () => {
        const states = Object.keys(States).map((s) => {
            return (
                <FormInputSelectMenuItem
                    key={s}
                    value={s}
                    avatar={{ letter: s.substring(0, 1), styleClass: propertiesStyles[s.toLowerCase()] }}
                    type={'iconRight'}>
                    {t(`Properties.States.${States[s]}`)}
                </FormInputSelectMenuItem>
            );
        });
        states.unshift(
            <FormInputSelectMenuItem key={'unset'} value={''}>
                {t('Properties.States.Unset')}
            </FormInputSelectMenuItem>
        );
        return states;
    };

    const getTypeOptions = () => {
        const types = Object.keys(TrailerTypes).map((s) => {
            return (
                <FormInputSelectMenuItem key={s} value={s}>{t(`Properties.TruckTypes.${TrailerTypes[s]}`)}</FormInputSelectMenuItem>
            );
        });
        types.unshift(
            <FormInputSelectMenuItem key={'unset'} value={''}>
                {t('Properties.TruckTypes.Unset')}
            </FormInputSelectMenuItem>
        );
        return types;
    };

    const handleSubmit = (e: any) => {
        onSubmit?.(e);
        // TODO: API request
    }

    return (
        <DialogBox open={open}>
            <DialogTitle id="create-truck-layout-title" className={styles['dialog-title']}>
                {t('TruckConfigurationDialog.Title')}
                <IconButton onClick={handleClose}>
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <PerfectScrollbar options={{ suppressScrollX: true }}>
                    <form className={styles['truck-creation-container']} onSubmit={handleSubmit}>
                        <FormInputText classes={{ container: styles['input-container'] }} control={control} name={'name'} label={t('TruckConfigurationDialog.Name')} placeholder={t('TruckConfigurationDialog.Name')} rules={{ required: true }} />
                        <FormInputSelect classes={{ container: styles['input-container'] }} search control={control} name={'state'} label={t('TruckConfigurationDialog.State')}>
                            {getStateOptions()}
                        </FormInputSelect>
                        <FormInputText classes={{ container: styles['input-container'] }} control={control} name={'length'} label={t('TruckConfigurationDialog.Length')} placeholder={t('TruckConfigurationDialog.Length')} InputProps={{ type: 'number' }} rules={{ required: true }} />
                        <FormInputText classes={{ container: styles['input-container'] }} control={control} name={'width'} label={t('TruckConfigurationDialog.Width')} placeholder={t('TruckConfigurationDialog.Width')} InputProps={{ type: 'number' }} rules={{ required: true }} />
                        <FormInputText classes={{ container: styles['input-container'] }} control={control} name={'depth'} label={t('TruckConfigurationDialog.Depth')} placeholder={t('TruckConfigurationDialog.Depth')} InputProps={{ type: 'number' }} rules={{ required: true }} />
                        <FormInputText classes={{ container: styles['input-container'] }} control={control} name={'height'} label={t('TruckConfigurationDialog.Height')} placeholder={t('TruckConfigurationDialog.Height')} InputProps={{ type: 'number' }} rules={{ required: true }} />
                        <FormInputSelect classes={{ container: `${styles['input-container']} ${styles['input-full-width']}` }} search control={control} name={'type'} label={t('TruckConfigurationDialog.Type')}>
                            {getTypeOptions()}
                        </FormInputSelect>
                        <FormInputText classes={{ container: `${styles['input-container']} ${styles['input-full-width']}` }} control={control} name={'description'} label={t('TruckConfigurationDialog.Description')} placeholder={t('TruckConfigurationDialog.Description')} multiline minRows={3} maxRows={5} />
                    </form>
                </PerfectScrollbar>
            </DialogContent>
            <DialogActions>
                <Button key="submitButton" variant="contained" type="submit" disabled={!formState.isValid}>
                    {t('TruckConfigurationDialog.Create')}
                </Button>
            </DialogActions>
        </DialogBox>
    )
}

export default TruckConfigurationDialog

export interface TruckConfigurationDialogProps {
    open: boolean,
    onClose?: (e: any) => void;
    onSubmit?: (e: any) => void;
}