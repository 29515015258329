import { fetchSettings, UserSettingsContext } from 'context/UserSettingsContext';
import useSession from 'hooks/useSession';
import { Viewer } from 'hsbshareviewer';
import React, { ForwardedRef, useContext, useEffect, useImperativeHandle, useRef } from 'react';
import appConfig from 'services/config';
import Config from './Helpers/ViewerConfig';

const ViewerCanvas = React.forwardRef(
    (
        props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & { config?: Config },
        ref: ForwardedRef<Viewer>
    ) => {
        let { children, id, config, ...divProps } = props;
        const { getSession, isGuest } = useSession();
        const { settings } = useContext(UserSettingsContext);
        const canvas = useRef();
        const viewer = useRef<Viewer>();
        // Setting the viewer
        useImperativeHandle(ref, () => {
            if (!canvas.current) return null;
            if (viewer.current) return viewer.current;
            viewer.current = new Viewer(canvas.current, config);
            viewer.current.initialize(appConfig.server, getSession());
            if (!isGuest()) {
                let prefSettings = settings?.preferenceSettings;
                if (!prefSettings) {
                    fetchSettings()
                        .then((fetchedSettings) => {
                            if (fetchedSettings) viewer.current.setFormatting(fetchedSettings?.preferenceSettings);
                        })
                        .catch((err) => {
                            if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                                console.error('Model viewer: Could not fetch settings!', err);
                            } else {
                                console.error('Model viewer: Could not fetch settings!');
                            }
                        });
                } else {
                    viewer.current.setFormatting(prefSettings);
                }
            }
            return viewer.current;
        });

        // Update settings if ened be
        useEffect(() => {
            if (settings) viewer.current?.setFormatting(settings?.preferenceSettings);
        }, [settings]);

        useEffect(() => {
            return () => {
                viewer.current?.cleanUp();
                viewer.current = null;
            };
        }, [canvas, ref]);

        return (
            <div
                id={id + '-canvas'}
                ref={canvas}
                {...divProps}
                style={{ backgroundColor: '#e0dfda', height: '100%', width: '100%', overflow: 'hidden', position: 'relative' }}>
                {children}
            </div>
        );
    }
);
export { ViewerCanvas };
