import { IconButton, Box } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import styles from "./HierarchyPanel.module.scss";
import { TreeView } from "@mui/lab";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { NodeData } from "./NodeData";
import ConsoleNode from "./ConsoleNode";

export default function ConsoleTree(props: ConsoleTreeProps): JSX.Element {
  let { root, onNodeSelect, onNodeToggle, onNodeHover, selected, searchTerm, onIsolation, isolatedHandle, setScroll, } = props;
  const treeRoot = useRef(null);
  const containerElement = useRef<HTMLUListElement>(null);
  const [currSelection, setCurrSelection] = useState<string[]>([]);
  const [currExpanded, setCurrExpanded] = useState<string[]>([]);
  const [defaultExpansionLevel] = useState(3);
  const [defaultMaxVisibleAmount] = useState(100);
  const [rootSelection, setRootSelection] = useState(null);
  const selectedNodes = useRef<NodeData[]>([]);

  useEffect(() => {
    // Handle selection of entities
    if (selected?.length > 0 && treeRoot) {
      // Get entities for visuals
      let entities = selected;

      // Get nodes for entities
      let nodes = entities.map((entity) => {
        if (!entity) return null;
        let start: NodeData = treeRoot.current?.children?.find(
          (child) => child.name === entity.hsbmodel.DrawingName
        );
        if (!start) return null;
        return findNodeByEntityDepthFirst(start, entity.handle);
      });

      // Filter out null values
      nodes = nodes.filter((n) => n);
      selectedNodes.current.forEach((n) => (n.selected = false));
      nodes.forEach((n) => (n.selected = true));
      selectedNodes.current = nodes;

      // Add groups for which all the children are selected
      nodes = addFullGroupNodes(nodes);

      // Set selection
      let temp = nodes.map((node) => node.dbId.toString())
        .filter((id, index, arr) => arr.findIndex((tempId) => tempId === id) === index)
        .sort((a, b) => parseInt(a) - parseInt(b));
      setCurrSelection(temp);
      setRootSelection(temp[0]);

      //Scroll logic
      if (temp?.length > 0) {
        // Timeout is necessary for the expansion
        setTimeout(() => {
          let rootElement = document.getElementById("TreeItem-" + temp[0]);
          if (rootElement) {
            setScroll(Math.max(rootElement.offsetTop - 100, 0));
          }
        }, 200);
      }

      // Expand the path only if no search term
      if (!(searchTerm?.length > 0)) {
        let parentNodes = nodes.map((node) => getPathToRoot(node)).flat();
        parentNodes = parentNodes.filter(onlyUnique);
        if (selected.length > 0)
          setCurrExpanded(parentNodes.map((node) => node.dbId.toString()));
      }
    } else {
      setCurrSelection([])
    };

    // If no root yet or not the correct root
    if (!treeRoot || treeRoot.current?.dbId !== root.dbId) {
      let newTree = getChildrenOfGivenRoot();
      setDefaultExpension(newTree);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  useEffect(() => {
    let newTree: any = getChildrenOfGivenRoot();
    if (searchTerm?.length > 0) {
      newTree.children = newTree.children.filter((child) => {
        return !prune(child, searchTerm);
      });
      setCurrExpanded(concatChildren(newTree, []));
    } else {
      setDefaultExpension(newTree);
    }
    treeRoot.current = newTree;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  // ---- UTIL METHODS ----
  /**
   * Performs a depth first search in the tree for the given node ID
   * @param node The root of the search
   * @param id The ID for the searched node
   * @returns The node if found, null otherwise.
   */
  const findNodeDepthFirst = (node: NodeData, id: string): NodeData => {
    if (node.dbId.toString() === id) { return node };

    let foundNode: any = null;
    node.children?.some((child) => {
      let node = findNodeDepthFirst(child, id);
      if (node) { foundNode = node };
      return foundNode !== null;
    });
    return foundNode;
  };

  const concatChildren = (node: NodeData, array: string[]): string[] => {
    if (node.children?.length > 0) {
      for (let i = 0; i < node.children.length; ++i) {
        array.concat(concatChildren(node.children[i], array));
      }
    }
    array.push(node.dbId.toString());
    return array;
  };

  const getChildrenOfGivenRoot = () => {
    // Start with children of given root
    let newRoot = {
      children: root?.children?.map((child) => {
        return new NodeData(child);
      }),
      dbId: root.dbId,
    };
    treeRoot.current = newRoot;
    return newRoot;
  };

  const setDefaultExpension = (root: any) => {
    // Set default expansion level
    let expandedIDs = [];
    let curr = [root];
    for (let level = 0; level < defaultExpansionLevel; level++) {
      curr = curr.map((node) => node.children).flat().filter((n) => !!n);
      if (curr?.length === 0 || expandedIDs.length > defaultMaxVisibleAmount) {
        break;
      }
      let totalChildren = curr.map((node) => node.children).flat().filter((n) => !!n);
      if (totalChildren.length + expandedIDs.length > defaultMaxVisibleAmount) {
        break;
      }
      expandedIDs.push(...curr.filter((node) => node.hasGeometry).map((node) => node.dbId.toString()));
    }
    setCurrExpanded(expandedIDs);
  };

  /**
   * Recursively prunes the tree from any nodes that dont have any geom.
   * @param node Starting node from which to test
   * @param depth Current depth from the starting node
   * @returns True if a node should be pruned, false otherwise.
   */
  const prune = (node: NodeData, name: string) => {
    // IF children we iterate over them first. If a child should not be pruned we want to keep the parent.
    if (node.children?.length > 0) {
      for (let i = 0; i < node.children.length; ++i) {
        let childPrune = prune(node.children[i], name);
        if (childPrune) {
          node.children.splice(i, 1);
          --i;
        }
      }
    }
    let markForDelete = !node.name.toLowerCase().includes(name.toLowerCase());
    // IF marked for delete and node has no children this too should be deleted
    return markForDelete && !(node.children?.length > 0);
  };

  /**
   * Checks whether a node is a descnedant of the given start node.
   * @param start The start node fo the lookup
   * @param node The node to check if it's on the start node's branch
   * @returns True if the node is on the startnodes branch, false otherwise
   */
  const isNodeOnBranch = (start: NodeData, node: NodeData): boolean => {
    if (!start.children) { return false };
    if (start.children?.includes(node)) { return true };
    return start.children?.some((child) => {
      return isNodeOnBranch(child, node);
    });
  };

  /**
   * Performs a depth first search in the tree for the given entity handle
   * @param node The root of the search
   * @param handle The handle for the searched entity
   * @returns The node with the corresponding entity handle if found, null otherwise.
   */
  const findNodeByEntityDepthFirst = (node: NodeData, handle: string): NodeData => {
    if (handle !== "site" && node.entity?.handle === handle) return node;
    let foundNode: NodeData = null;
    node.children?.some((child) => {
      let node = findNodeByEntityDepthFirst(child, handle);
      if (node) { foundNode = node };
      return foundNode !== null;
    });
    return foundNode;
  };

  /**
   * Returns the path from the given node to the root of the tree
   * @param node The node for which to return the path
   * @returns {NodeData[]} Array of nodes from given node -> root. Empty [] if given node has no parent.
   */
  const getPathToRoot = (node: NodeData) => {
    let currNode = node;
    if (!currNode) { return [] };
    let nodes: NodeData[] = [currNode];
    if (!node.parent) { return nodes };
    while (currNode.parent) {
      nodes.push(currNode.parent);
      currNode = currNode.parent;
    }
    return nodes;
  };

  /**
   * Returns all the top nodes of the possible branches given in the array
   * @param nodes The array of nodes for which to check the branches.
   * @returns AN array of top nodes
   */
  const getTopOfBranches = (nodes: NodeData[]): NodeData[] => {
    // Sorting makes this more efficient as the top nodes are more likely to be higher level. -> no need to check lower nodes then.
    let out = nodes.sort((a, b) => a.level - b.level);
    for (let index = 0; index < out.length; index++) {
      const rootNode = out[index];
      out = out.filter((otherNode) => {
        if (rootNode === otherNode) { return true };
        let onBranch = !isNodeOnBranch(rootNode, otherNode);
        if (!onBranch) { ++index; }
        return onBranch;
      });
    }
    return out;
  };

  /**
   * Recursively checks the tree whether parent nodes should be added to the selection.
   * @param nodes The start array for which to check the parents.
   */
  const addFullGroupNodes = (nodes: NodeData[]) => {
    if (!nodes || nodes.length === 0) return [];
    // First group nodes
    let groupNodes = nodes.filter((n) => n.parent);
    groupNodes = groupNodes.map((n) => {
      return n.parent;
    }); // Get parent nodes
    groupNodes = groupNodes.filter(onlyUnique); // Only unique
    groupNodes = groupNodes.filter((groupNode) => {
      return groupNode.children.every((child) => nodes.includes(child));
    }); // Only if all their children are in the array
    // Get the top of possible branches.
    let topNodes = getTopOfBranches(groupNodes);
    // Recursively check parents
    return nodes.concat(groupNodes, addFullGroupNodes(topNodes));
  };

  /**
   * Filters an array to only have unique values.
   * @param value
   * @param index
   * @param self
   * @returns
   */
  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  // ---- EVENTS ----

  const handleNodeSelect = (e, nodeIDs, root) => {
    e.stopPropagation();
    try {
      // If icon -> skip
      if (e.target.nodeName === "svg" || e.target.nodeName === "path") { return; }
      // get nodes for node IDS
      let nodes: NodeData[] = nodeIDs.map((id) => findNodeDepthFirst(root, id)).filter((node) => node !== null);
      if (!nodes || nodes.length === 0) return;
      // Get entities for nodes
      let entities = nodes.map((n) => n.getAllEntities()).flat();
      // Make sure only unique entities in array
      entities = entities.filter(onlyUnique);
      if (onNodeSelect) { onNodeSelect(entities, root); }
    } catch (err) {
      console.error(err);
    }
  };

  const handleNodeToggle = (node: NodeData, visible) => {
    let hitNode = new NodeData(root?.nodeModel?.getNodeById(root, node.dbId));
    let entities = hitNode?.getAllEntities([]);
    if (onNodeToggle) onNodeToggle(entities, visible);
  };

  const handleNodeHover = (node: NodeData, hovered) => {
    let hitNode = new NodeData(root?.nodeModel?.getNodeById(root, node.dbId));
    let entities = hitNode?.getAllEntities([]);
    if (onNodeHover) onNodeHover(entities, hovered);
  };

  const handleNodeExpansion = (e, nodeID) => {
    setCurrExpanded(nodeID);
  };

  return (
    <Box className={styles["console-container"]}>
      {treeRoot.current?.children?.map((child) => {
        return (
          <TreeView
            ref={containerElement}
            key={"TreeView" + child.name + "-" + child.dbId}
            aria-label="Model navigator"
            defaultCollapseIcon={
              <IconButton sx={{ padding: "0" }}>
                <ArrowDropDownIcon />
              </IconButton>
            }
            defaultExpandIcon={
              <IconButton sx={{ padding: "0" }}>
                <ArrowRightIcon />
              </IconButton>
            }
            onNodeSelect={(e, nodeIDs) => handleNodeSelect(e, nodeIDs, child)}
            onNodeToggle={handleNodeExpansion}
            selected={currSelection}
            expanded={currExpanded}
            multiSelect
          >
            <ConsoleNode
              key={"NodeTree-" + child.name + "-" + child.dbId}
              node={child}
              onVisibility={handleNodeToggle}
              onHover={handleNodeHover}
              searchTerm={searchTerm}
              onIsolation={onIsolation}
              isolatedHandle={isolatedHandle}
              rootSelection={rootSelection}
            />
          </TreeView>
        );
      })}
    </Box>
  );
}

export interface ConsoleTreeProps {
  root: any;
  onNodeSelect?: any;
  onNodeToggle?: any;
  onNodeHover?: any;
  setScroll?: any;
  propagateSelection?: boolean;
  propagateToggle?: boolean;
  multiSelect?: boolean;
  expanded?: any[];
  selected?: any[];
  searchTerm: string;
  onIsolation: (node: NodeData, isolated: boolean) => void;
  isolatedHandle: string;
}
