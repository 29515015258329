import { faClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography } from '@mui/material';
import { DataGrid, GridColDef, gridStringOrNumberComparator } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import styles from './PackageList.module.scss';

function PackageList(props: PackageListProps) {
    const { packages, onPackageRowClick } = props;
    const { t } = useTranslation('common');
    const columns: GridColDef[] = [
        {
            field: 'nameAndDate',
            headerName: t('PackageList.Name'),
            flex: 1,
            filterable: false,
            valueGetter: (params) => {
                return {
                    name: params.row.name,
                    date: params.row.date,
                };
            },
            renderCell: (params) => (
                <Box display={'flex'} flexDirection={'column'} gap={'3px'} minWidth={'0%'}>
                    <Typography className={`${styles['cell-content']} ${styles['force-ellipsis']}`}>{params.value.name}</Typography>
                    <Box className={styles['cell-content-sub']}>
                        <FontAwesomeIcon icon={faClock} />
                        <Typography className={styles['force-ellipsis']}>{formatDate(params.value.date)}</Typography>
                    </Box>
                </Box>
            ),
            sortComparator: (v1, v2) => {
                return gridStringOrNumberComparator(v1.name, v2.name, null, null);
            },
        },
        { field: 'truck', headerName: t('PackageList.Truck'), flex: 1 },
        { field: 'items', headerName: t('PackageList.Items'), type: 'number', flex: 1, renderCell: (params) => params.value.length },
        { field: 'attachments', headerName: t('PackageList.Attachments'), type: 'number', flex: 1 },
        {
            field: 'createdBy',
            headerName: t('PackageList.CreatedBy'),
            flex: 1,
            filterable: false,
            renderCell: (params) => (
                <Box display={'flex'} gap={'7px'} alignItems={'center'} minWidth={'0%'}>
                    {params.value.avatar.URI ? (
                        <img src={params.value.avatar.URI} alt="profile avatar" />
                    ) : (
                        <Box
                            className={styles['user-avatar']}
                            display={'flex'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            sx={{ background: params.value.avatar.color }}>
                            <Typography>{params.value.avatar.letter}</Typography>
                        </Box>
                    )}
                    <Typography className={`${styles['cell-content']} ${styles['force-ellipsis']}`}>{params.value.name}</Typography>
                </Box>
            ),
            sortComparator: (v1, v2) => {
                return gridStringOrNumberComparator(v1.name, v2.name, null, null);
            },
        },
    ];

    const formatDate = (date) => {
        // Ensure the input is a Date object
        if (!(date instanceof Date)) {
            date = new Date(date);
        }

        // Get the current and yesterday's date
        const currentDate = new Date(),
            yesterday = new Date(currentDate);
        yesterday.setDate(currentDate.getDate() - 1);

        // Check if the date is today
        if (
            date.getDate() === currentDate.getDate() &&
            date.getMonth() === currentDate.getMonth() &&
            date.getFullYear() === currentDate.getFullYear()
        ) {
            // Format time as "h:mm AM/PM"
            const formattedTime = date.toLocaleString('en-US', {
                hour: 'numeric',
                minute: '2-digit',
                hour12: true,
            });

            return `Today at ${formattedTime}`;
        }
        // Check if the date is yesterday
        else if (
            date.getDate() === yesterday.getDate() &&
            date.getMonth() === yesterday.getMonth() &&
            date.getFullYear() === yesterday.getFullYear()
        ) {
            // Format time as "h:mm AM/PM"
            const formattedTime = date.toLocaleString('en-US', {
                hour: 'numeric',
                minute: '2-digit',
                hour12: true,
            });

            return `Yesterday at ${formattedTime}`;
        } else {
            // Format date as "DD/MM/YYYY at h:mm AM/PM"
            const formattedDate = date.toLocaleString('en-US', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: 'numeric',
                minute: '2-digit',
                hour12: true,
            });

            return formattedDate;
        }
    };

    return (
        <Box className={styles['data-grid-wrapper']}>
            <DataGrid
                rows={packages}
                columns={columns}
                autoPageSize
                checkboxSelection
                disableRowSelectionOnClick
                classes={{
                    cellContent: styles['cell-content'],
                    cell: styles['cell'],
                    cellCheckbox: styles['cell'],
                    footerContainer: styles['data-grid-footer'],
                    checkboxInput: styles['checkbox-input'],
                    root: styles['data-grid-root'],
                    columnHeader: styles['cell-header'],
                }}
                onRowClick={(e: any) => onPackageRowClick(e.row)}
            />
        </Box>
    );
}

export default PackageList;

export interface PackageListProps {
    packages: any[];
    onPackageRowClick: (packageObject: any) => void;
}
