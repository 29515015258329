import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, BoxProps, Typography } from "@mui/material";
import ContextualMenu from "components/Shared/ContextualMenu/ContextualMenu";
import { useRef, useState } from "react";
import styles from './StackingContent.module.scss';

function StackingContent(props: StackingContentProps) {
    const { entity, variant, selectable, onClick, onDelete, contextualMenuItems, ...boxProps } = props;
    const [selected, setSelected] = useState(false);
    const ref = useRef<HTMLDivElement>();

    const handleSelection = () => {
        selectable && setSelected(!selected);
        onClick?.(entity);
    }

    const handleDelete = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        onDelete?.(entity.id);
    }

    return (
        <>
            <Box key={boxProps.key} onClick={handleSelection} onContextMenu={handleSelection} className={`${styles['stacking-content-container']} ${selectable ? styles['selectable'] : ''} ${selected ? styles['selected'] : ''} ${styles[variant || 'light']}`} ref={ref}>
                <Typography>{entity.name}</Typography>
                {
                    onDelete && <FontAwesomeIcon icon={faClose} onClick={handleDelete} />
                }
            </Box>
            {
                contextualMenuItems && <ContextualMenu parent={ref}>
                    {contextualMenuItems}
                </ContextualMenu>
            }
        </>
    )
}

export default StackingContent

export interface StackingContentProps extends Omit<BoxProps, 'onClick'> {
    entity: any;
    variant?: 'light' | 'dark';
    selectable?: boolean;
    onClick?: (key: string) => void;
    onDelete?: (key: string) => void;
    contextualMenuItems?: any;
}