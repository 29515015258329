import { Box, TabProps } from '@mui/material';

export default function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...tabProps } = props;

    return (
        <Box role="tab" hidden={value !== index} id={`tab-${index}`} aria-labelledby={`tab-${index}`} {...tabProps}>
            {value === index &&
                children
            }
        </Box>
    );
}

export interface TabPanelProps extends Omit<TabProps, "children"> {
    children: React.ReactNode;
    index: any;
    value: any;
}