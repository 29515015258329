import AddIcon from '@mui/icons-material/Add';
import { Box, CircularProgress, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import ListSearch from 'components/Shared/List/Search/ListSearch';
import SnackBarMessage, { ISnackBarData } from 'components/Shared/snackBar/SnackBarMessage';
import { useEffect, useLayoutEffect, useRef, useState, useTransition } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { default as PerfectScrollbar, default as ScrollBar } from 'react-perfect-scrollbar';
import { useNavigate } from 'react-router-dom';
import API from 'services/rest-api';
import AddProjectDialog from './AddProjectDialog';
import ProjectItem from './ProjectItem';
import styles from './ProjectPage.module.scss';

const projectSkeleton = (amount: number) => {
    return [...Array(amount)].map((item, i) => (
        <ProjectItem
            state={null}
            notifications={null}
            projectId={null}
            projectName={null}
            teamName={null}
            updatedText={null}
            key={`prjtsSkell-${i}`}
            onProjectDelete={null}
        />
    ));
};

const refreshElement = (finished: boolean, loading: boolean, t) => {
    return (
        <Box className={styles.refreshPrompt}>
            {loading ? (
                <CircularProgress className={styles['circularProgress']} color="primary" />
            ) : (
                <Typography variant="subtitle1" color={'info'} className={styles.refreshPromptText}>
                    {finished ? t('ProjectsController.Release') : t('ProjectsController.Pull')}
                </Typography>
            )}
        </Box>
    );
};

export default function ProjectsController() {
    const { t } = useTranslation('common');
    const scrollbar = useRef<ScrollBar>(null);
    const [activeCall, setActiveCall] = useState<Promise<any>>(null);
    const hasMore = useRef<boolean>(true);
    const infiniteScroll = useRef<InfiniteScroll>(null);
    const limit = useRef<number>(40);
    const skip = useRef<number>(0);
    const search = useRef<string>(null);
    const [open, setOpen] = useState(false);
    const [snackBarData, setSnackBarData] = useState<ISnackBarData>({ open: false, severity: 'info', message: '', });
    const [projectData, setProjectData] = useState([]);
    const [isPending, startTransition] = useTransition();
    const navigate = useNavigate();

    const handleProjectDelete = (projectId: string) => {
        setProjectData(projectData.filter(project => project?.projectDB?.projectID !== projectId));
    }

    const handleRefresh = () => {
        skip.current = 0;
        if (!activeCall) {
            let call = fetchProjects(true);
            setActiveCall(call);
        }
    };

    const fetchProjects = (refresh: boolean = false) => {
        return API.getUserProjects(limit.current, skip.current, search.current).then((projects) => {
            if (projects.length < limit.current) {
                hasMore.current = false;
            }
            let allProjects = [];
            if (!refresh) {
                allProjects = projectData.filter((projData) => projData?.props?.projectId !== null); //filter out loading screens;
            }
            allProjects = allProjects.concat(projects);
            startTransition(() => setProjectData(allProjects));
            skip.current = allProjects.length;
            return Promise.resolve(allProjects);
        }).catch((err) => {
            console.error(err);
            return Promise.reject(err);
        }).finally(() => {
            setActiveCall(null);
        });
    };

    /**
     * Initial fetch
     */
    useEffect(() => {
        window.removeEventListener('resize', () => startTransition(() => { }));
        window.addEventListener('resize', () => startTransition(() => { }));

        return () => {
            window.removeEventListener('resize', () => startTransition(() => { }));
            skip.current = 0;
        };
    }, []);

    /**
     * Hacky way to make sure the infinite scroll fetches enough.
     * If the initial fetch fits inside the div without needing a scroll the infinite scroll never triggers.
     * cfr. https://github.com/ankeetmaini/react-infinite-scroll-component/issues/369
     */
    const fetchToFill = () => {
        let ele = document.getElementsByClassName('infinite-scroll-component')?.[0];
        let container = document.getElementById('projectPageRoot');
        if (!activeCall && hasMore.current && (projectData.length === 0 || ele?.clientHeight < container.clientHeight)) {
            setActiveCall(fetchProjects());
        }
    };

    useLayoutEffect(() => {
        if (!isPending) {
            fetchToFill();
        }
        return () => { };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPending]);

    const onCreateProject = (data: any) => {
        API.createProject(data.teamId, data.name).then((p) => {
            setSnackBarData({ open: true, severity: 'success', message: t('ProjectsController.AddProjectSuccess', { name: p.data.projectData.name, }) });
            const project = {
                projectDB: {
                    projectID: p.data.projectID,
                    projectState: 'open',
                    name: p.data.projectData.name,
                    image: `./img/emptyproject.svg`,
                    state: 'open',
                },
                openedIssues: Array.from(Array(Math.floor(Math.random() * 5)).keys()),
                teamDB: { name: p.data.projectData.teamName },
                openedOn: p.data.modelNode.created.on,
            };
            let copy = Array.from(projectData);
            copy.push(project);
            setProjectData(copy);
            navigate(`../project/${p.data.projectID}?lpanel=models&context=new`);
        }).catch((e) => {
            setSnackBarData({ open: true, severity: 'error', message: t('ProjectsController.AddProjectFailure') });
        });
    };

    /**
     * Handles a change in the given search term
     * @param query The entered search term
     */
    const handleFilter = (query: string | null) => {
        setProjectData([]);
        search.current = query;
        skip.current = 0;
        setActiveCall(fetchProjects(true));
    };

    return (
        <div id="projectPageRoot" data-testid="ProjectsController">
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="h5" sx={{ paddingLeft: '15px' }}>
                    {t('ProjectsController.AllProjects')}
                </Typography>
                <Stack direction="row" justifyContent="flex-start" alignItems="center">
                    <ListSearch onFilter={handleFilter} />
                    <Tooltip title={t('AddProjectDialog.Title')}>
                        <IconButton onClick={() => setOpen(true)}>
                            <AddIcon />
                        </IconButton>
                    </Tooltip>
                </Stack>
            </Stack>

            <Box className={styles['loading-box']}></Box>

            <PerfectScrollbar
                id="project-scrollbar"
                data-testid="project-scrollbar"
                style={{ height: 'calc(100vh - 118px)', position: 'relative' }}
                options={{ suppressScrollX: true }}
                ref={scrollbar}>
                <AddProjectDialog onClose={() => setOpen(false)} isOpen={open} onCreateProject={onCreateProject} />
                <InfiniteScroll
                    ref={infiniteScroll}
                    scrollableTarget={'project-scrollbar'}
                    data-testid="infinite-scroll"
                    dataLength={projectData.length}
                    next={fetchProjects}
                    refreshFunction={handleRefresh}
                    hasMore={hasMore.current}
                    loader={projectSkeleton(40)}
                    className={styles['infinite-scroll']}
                    pullDownToRefresh
                    pullDownToRefreshThreshold={100}
                    pullDownToRefreshContent={refreshElement(false, true, t)}
                    releaseToRefreshContent={refreshElement(true, !!activeCall, t)}>
                    {
                        projectData.map(project => {
                            return <ProjectItem
                                key={project?.projectDB?.projectID}
                                state={"open"}
                                notifications={project?.openedIssues?.length ?? 0}
                                projectId={project?.projectDB?.projectID}
                                projectName={project?.projectDB?.name}
                                teamName={project?.teamDB?.name}
                                updatedText={null}
                                thumbnail={project?.projectDB?.nodeModel?.thumbnailDataUrl}
                                onProjectDelete={handleProjectDelete}
                            />
                        })
                    }
                </InfiniteScroll>
                <SnackBarMessage
                    severity={snackBarData.severity}
                    message={snackBarData.message}
                    open={snackBarData.open}
                    onSetOpen={setSnackBarData}
                />
            </PerfectScrollbar>
        </div>
    );
}
