import {
    faArrowLeft,
    faArrowsLeftRight,
    faArrowUpRightFromSquare,
    faBoxOpen,
    faClose,
    faComments,
    faDownload,
    faLocationDot,
    faPaperclip,
    faPlus,
    faTrash,
    faTurnUp,
    faUpDown,
    faUpRightAndDownLeftFromCenter,
    faWeightHanging,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton, Typography } from '@mui/material';
import ContextualMenuItem from 'components/Shared/ContextualMenu/ContextualMenuItem';
import { FormInputFile } from 'components/Shared/FormComponents/FormInputFile';
import { FormInputText } from 'components/Shared/FormComponents/FormInputText';
import useSession from 'hooks/useSession';
import { Dispatch, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import restAPI from '../../../services/rest-api';
import StackingContent from '../StackingContent/StackingContent.module';
import styles from './TruckDetail.module.scss';

function TruckDetail(props: TruckDetailProps) {
    const { setSelectedTruck, onPackageSelection, onPackageCreation, onPackageRemove } = props;
    const { t } = useTranslation('common');
    const [truckObj, setTruckObj] = useState(props.truckObj);
    const { getSession } = useSession();
    const [isDraggedUpon, setIsDraggedUpon] = useState(false);
    const [, setFormValues] = useState();
    const [contentSelection, setContentSelection] = useState([]);
    const { control, getValues, setValue } = useForm<any>({
        defaultValues: {
            documents: [],
            comments: truckObj.details.comments,
        },
        mode: 'onChange',
    });

    useEffect(() => {
        setTruckObj(props.truckObj);
    }, [props.truckObj]);

    const getClassForExtension = (fileType: string) => {
        if (fileType?.includes('image')) {
            return 'image';
        } else if (fileType?.includes('pdf')) {
            return 'pdf';
        }
    };

    const handleAttachmentsAdd = (e: any) => {
        const dt = new DataTransfer();
        e.forEach((file: any) => dt.items.add(file));
        // TODO: hook up to API call
        // restAPI.uploadDocumentsToNode(truckObj._id, dt.files).then((filesResult) => {
        //     setValue('documents', filesResult.documents || null);
        //     setFormValues(filesResult.documents);
        // }).catch((err) => {
        //     console.error(err);
        // });
    };

    const handleFileDeletion = (e: any, fileData: any) => {
        e.stopPropagation();
        // TODO: hook up to API call
        // restAPI.deleteFileFromNode(truckObj._id, fileData.name).then((result) => {
        //     setValue('documents', result?.documents?.length > 0 ? result.documents : null);
        // }).catch((err) => {
        //     console.error(err);
        // });
    };

    const handleFilePreview = (file: any) => {
        if (!file.path) {
            return;
        }

        const url = restAPI.getFileDownloadURL(file.path, getSession()?.token);
        window.open(url);
    };

    const handleFileDownload = (e: any, file: any) => {
        e.stopPropagation();
        e.preventDefault();
        const path = restAPI.getFileDownloadURL(file.path, getSession()?.token);
        fetch(path)
            .then((res) => res.blob())
            .then((blob) => {
                // Create link element to download file
                let url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                document.body.appendChild(link);
                link.href = url;
                link.download = file.name;

                // Start download
                link.click();

                // Cleanup
                window.URL.revokeObjectURL(url);
                document.body.removeChild(link);
            })
            .catch((err) => {
                console.error('Could not download file!', err);
            });
    };

    const handleDragEnter = (e: any) => {
        e.preventDefault();
        if (!isDraggedUpon) {
            setIsDraggedUpon(true);
        }
    };

    const handleDragLeave = (e: any) => {
        if (!e.relatedTarget || e.currentTarget.contains(e.relatedTarget)) {
            return;
        }
        e.preventDefault();
        e.stopPropagation();
        setIsDraggedUpon(false);
    };

    const handleDrop = (e: any) => {
        setIsDraggedUpon(false);
        if (e.dataTransfer?.files?.length > 0) {
            handleAttachmentsAdd(Array.from(e.dataTransfer.files));
        }
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDragOver = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleContentDelete = (key: string) => {
        onPackageRemove(truckObj.id, key);
        setContentSelection(contentSelection.filter((content) => content.id !== key));
    };

    const handleContentSelect = (packageContent) => {
        const isIdSelected = contentSelection.includes(packageContent);

        if (isIdSelected) {
            setContentSelection(contentSelection.filter((content) => content.id !== packageContent.id));
        } else {
            setContentSelection([...contentSelection, packageContent]);
        }
    };

    const handleCreatePackage = () => {
        // TODO: API call for creating a package
        // if succesfull add package to list and navigate to package
        onPackageCreation(contentSelection, truckObj.id, true);
        setContentSelection([]);
    };

    const getPackageDisplay = (entity: any) => {
        return (
            <Box className={styles['package-display-container']} key={entity.id}>
                <Box className={styles['package-display-header']}>
                    <Box className={styles['package-display-header-info']} onClick={() => onPackageSelection(entity.id)}>
                        <FontAwesomeIcon icon={faBoxOpen} />
                        <Typography>{entity.name}</Typography>
                        <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                    </Box>
                    <FontAwesomeIcon icon={faClose} onClick={() => handleContentDelete(entity.id)} />
                </Box>
                <Typography className={styles['package-display-subtext']}>
                    {t('PackageDetail.ItemsSubtitle', { amount: entity.items.length })}
                </Typography>
                <Box className={styles['package-display-content-container']}>
                    {entity.items.map((item) => {
                        return <StackingContent key={item.id} entity={item} variant="dark" />;
                    })}
                </Box>
            </Box>
        );
    };

    const formatDate = (date: Date) => {
        // Ensure the input is a Date object
        if (!(date instanceof Date)) {
            date = new Date(date);
        }

        return date
            .toLocaleString('en-GB', {
                day: 'numeric',
                month: 'long',
                hour: 'numeric',
                minute: '2-digit',
            })
            .replace(' at', ',');
    };

    return (
        <PerfectScrollbar
            id="truck-container"
            className={`${styles['truck-container']} ${isDraggedUpon && styles['container-dragged']}`}
            onDrop={handleDrop}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragOver={handleDragOver}>
            <Box className={styles['header-container']}>
                <FontAwesomeIcon icon={faArrowLeft} onClick={() => setSelectedTruck(null)} />
                <Typography>{truckObj.name}</Typography>
            </Box>
            <Box className={styles['content-container']}>
                <Box className={styles['left-content-container']}>
                    <Box className={styles['detail-container']}>
                        <Box className={styles['detail-header']}>
                            <FontAwesomeIcon icon={faWeightHanging} />
                            <Typography>{t('TruckDetail.Weight')}</Typography>
                        </Box>
                        <Box className={styles['detail-value']}>{truckObj.details.weight || '-'}</Box>
                    </Box>
                    <Box className={styles['detail-container']}>
                        <Box className={styles['detail-header']}>
                            <FontAwesomeIcon icon={faArrowsLeftRight} />
                            <Typography>{t('TruckDetail.Length')}</Typography>
                        </Box>
                        <Box className={styles['detail-value']}>{truckObj.details.length || '-'}</Box>
                    </Box>
                    <Box className={styles['detail-container']}>
                        <Box className={styles['detail-header']}>
                            <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
                            <Typography>{t('TruckDetail.Width')}</Typography>
                        </Box>
                        <Box className={styles['detail-value']}>{truckObj.details.width || '-'}</Box>
                    </Box>
                    <Box className={styles['detail-container']}>
                        <Box className={styles['detail-header']}>
                            <FontAwesomeIcon icon={faUpDown} />
                            <Typography>{t('TruckDetail.Height')}</Typography>
                        </Box>
                        <Box className={styles['detail-value']}>{truckObj.details.height || '-'}</Box>
                    </Box>
                    <Box className={styles['detail-container']}>
                        <Box className={styles['detail-header']}>
                            <FontAwesomeIcon icon={faLocationDot} />
                            <Typography>{t('TruckDetail.Departure')}</Typography>
                        </Box>
                        <Box className={styles['detail-value']}>
                            {truckObj.location.departure.name || '-'}
                            {truckObj.location.departure.date && (
                                <Typography className={styles['extra']}>{`- ${formatDate(truckObj.location.departure.date)}`}</Typography>
                            )}
                        </Box>
                    </Box>
                    <Box className={styles['detail-container']}>
                        <Box className={styles['detail-header']}>
                            <FontAwesomeIcon icon={faTurnUp} rotation={90} />
                            <Typography>{t('TruckDetail.Destination')}</Typography>
                        </Box>
                        <Box className={styles['detail-value']}>
                            {truckObj.location.destination.name || '-'}
                            {truckObj.location.destination.date && (
                                <Typography className={styles['extra']}>{`- ${formatDate(truckObj.location.destination.date)}`}</Typography>
                            )}
                        </Box>
                    </Box>
                    <Box className={`${styles['detail-container']} ${styles['detail-container-row']}`}>
                        <Box className={styles['detail-header']}>
                            <FontAwesomeIcon icon={faComments} />
                            <Typography>{t('TruckDetail.Comments')}</Typography>
                        </Box>
                        <FormInputText
                            control={control}
                            name={'comments'}
                            multiline
                            minRows={3}
                            maxRows={3}
                            placeholder="Comments"
                            classes={{ root: styles['comment-input'] }}
                        />
                    </Box>
                    <Box className={`${styles['detail-container']} ${styles['detail-container-row']} ${styles['attachments-container']}`}>
                        <Box className={styles['detail-header']}>
                            <FontAwesomeIcon icon={faPaperclip} />
                            <Typography>{t('TruckDetail.Attachments')}</Typography>
                            {getValues('documents').length > 0 && (
                                <>
                                    <Typography className={styles['attachments-amount']}>{getValues('documents').length}</Typography>
                                    <FormInputFile
                                        multiple
                                        control={control}
                                        name="documents"
                                        classes={{
                                            container: styles['inline-input-wrapper'],
                                            'input-container': styles['inline-input-container'],
                                        }}
                                        helperIcon={<FontAwesomeIcon icon={faPlus} />}
                                        onChange={(e) => handleAttachmentsAdd(e)}
                                    />
                                </>
                            )}
                        </Box>
                        {getValues('documents').length > 0 ? (
                            <PerfectScrollbar
                                className={styles['scrollable-attachments']}
                                options={{ useBothWheelAxes: true, suppressScrollX: false, suppressScrollY: false }}>
                                {getValues('documents').map((attachment, index) => {
                                    return (
                                        <Box
                                            className={`${styles['attachment']} ${styles[getClassForExtension(attachment.type)]}`}
                                            sx={{ cursor: attachment.path ? 'pointer' : 'default' }}
                                            key={`${index} ${attachment.name}`}
                                            onClick={() => handleFilePreview(attachment)}>
                                            <Box className={styles['attachment-information']}>
                                                <Box>{attachment.name?.split('.').pop()}</Box>
                                                <Typography title={attachment.name}>{attachment.name?.replace(/\.[^/.]+$/, '')}</Typography>
                                            </Box>
                                            <Box className={styles['attachment-actions']}>
                                                {attachment.path && (
                                                    <IconButton aria-label="delete" onClick={(e) => handleFileDownload(e, attachment)}>
                                                        <FontAwesomeIcon icon={faDownload} />
                                                    </IconButton>
                                                )}
                                                <IconButton onClick={(e) => handleFileDeletion(e, attachment)}>
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </IconButton>
                                            </Box>
                                        </Box>
                                    );
                                })}
                            </PerfectScrollbar>
                        ) : (
                            <FormInputFile
                                multiple
                                name="documents"
                                control={control}
                                classes={{
                                    container: styles['attachments-input-wrapper'],
                                    'input-container': styles['attachments-input-container'],
                                }}
                                helperText={t('IssueDialog.AddAttachmentsHelp')}
                                helperIcon={<FontAwesomeIcon icon={faPaperclip} />}
                                onChange={(e) => handleAttachmentsAdd(e)}
                            />
                        )}
                    </Box>
                </Box>
                <Box className={styles['vertical-divider']}></Box>
                <Box className={styles['right-content-container']}>
                    <Box className={styles['truck-content-header']}>
                        <Typography>{t('TruckDetail.ItemsTitle', { name: truckObj.name })}</Typography>
                        <Typography>
                            {t('TruckDetail.ItemsSubtitle', {
                                entities: truckObj.items.filter((item) => item.type !== 'package').length,
                                packages: truckObj.items.filter((item) => item.type === 'package').length,
                            })}
                        </Typography>
                    </Box>
                    <Box className={`${styles['truck-content-wrapper']} ${truckObj.items.length === 0 ? styles['no-content'] : ''}`}>
                        {truckObj.items.length > 0 ? (
                            truckObj.items
                                .sort((a, b) => a.type?.localeCompare(b.type))
                                .map((entity) => {
                                    return entity.type === 'package' ? (
                                        getPackageDisplay(entity)
                                    ) : (
                                        <StackingContent
                                            key={entity.id}
                                            entity={entity}
                                            onDelete={handleContentDelete}
                                            selectable
                                            onClick={handleContentSelect}
                                            contextualMenuItems={
                                                <ContextualMenuItem onClick={handleCreatePackage} disabled={contentSelection.length === 0}>
                                                    <FontAwesomeIcon icon={faBoxOpen} />
                                                    {t('TruckDetail.CreatePackage', { items: contentSelection.length })}
                                                </ContextualMenuItem>
                                            }
                                        />
                                    );
                                })
                        ) : (
                            <Typography variant="subtitle1">{t('TruckDetail.GetStarted')}</Typography>
                        )}
                    </Box>
                </Box>
            </Box>
        </PerfectScrollbar>
    );
}

export default TruckDetail;

export interface TruckDetailProps {
    truckObj: any;
    setSelectedTruck: Dispatch<string>;
    onPackageSelection: (id: string) => void;
    onPackageCreation: (packageContent: any[], truckId: string, navigate?: boolean) => void;
    onPackageRemove: (truckId: string, packageId: string) => void;
}
