import AddIcon from '@mui/icons-material/Add';
import { Box, Drawer, DrawerClasses, DrawerProps, IconButton, Paper, Stack, Typography } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import PerfectScrollbar from 'react-perfect-scrollbar';
import styles from 'styles/Tab.module.scss';
import { FormInputFile } from './FormComponents/FormInputFile';
import ListFilter from './List/Filter/ListFilter';
import ListSearch from './List/Search/ListSearch';

const Tab = React.forwardRef((props: React.PropsWithChildren<TabProps>, ref) => {
    let { direction, open, onClose, title, children, filters, onFilter, onSearch, onFileSelectAddition, classes, actions, loading, scrollTop, onAddition, ...customProps } = props;
    const { control } = useForm<any>({
        defaultValues:
        {
            files: null
        },
        mode: "onChange"
    });

    // Merge the assigned classes
    let defClasses = {
        root: `${styles.tabStyles}`,
        paperAnchorRight: `${styles.rightDockedTab}`,
        paperAnchorLeft: `${styles.leftDockedTab}`,
        paper: `${styles.tabPaper}`,
    };
    if (classes) {
        Object.keys(classes).forEach((key) => {
            if (!defClasses[key]) {
                defClasses[key] = classes[key];
            } else {
                defClasses[key] += ' ' + classes[key];
            }
        });
    }

    const handleClose = (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
        if (onClose) onClose(event, reason);
    };

    // Scroll if scrollTop was set
    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.scrollTop = scrollTop;
        }
    }, [scrollTop]);

    const containerRef = useRef<HTMLElement>(null);
    return (
        <Drawer
            classes={defClasses}
            anchor={direction}
            disablePortal={true}
            open={open}
            onClose={handleClose}
            hideBackdrop
            disableAutoFocus
            disableRestoreFocus
            disableEnforceFocus
            transitionDuration={{ enter: 1, exit: 0 }}
            elevation={1}
            {...customProps}>
            <Paper
                square={true}
                style={{ gridArea: 'header', borderBottom: '1px solid #eeeeee' }}
                elevation={0}
                classes={{ root: `${classes?.content ?? ''}` }}>
                <Box className={styles.tabHeader} sx={{ border: '0px' }}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Typography variant="h5" className={styles.panelTitle}>
                            {title}
                        </Typography>
                        <Box className={styles['tab-actions']}>
                            {
                                onSearch &&
                                <ListSearch onFilter={onSearch} classes={{ root: styles.search }} />
                            }
                            {
                                onFilter && filters &&
                                <ListFilter onFilter={onFilter} filters={filters} />
                            }
                            {
                                onAddition &&
                                <IconButton onClick={onAddition}>
                                    <AddIcon color='primary' />
                                </IconButton>
                            }
                            {
                                onFileSelectAddition &&
                                <FormInputFile multiple control={control} name='files' classes={{ container: styles['inline-input-wrapper'], "input-container": styles['inline-input-container'] }} helperIcon={<AddIcon />} onChange={(e) => onFileSelectAddition(e)} />
                            }
                        </Box>
                    </Stack>
                </Box>
            </Paper>
            <PerfectScrollbar
                containerRef={(ref) => {
                    containerRef.current = ref;
                }}
                options={{ suppressScrollX: true }}
                style={{ gridArea: 'content', height: '100%' }}>
                <Box ref={ref} className={styles.skeletonContainer}>
                    {children}
                </Box>
            </PerfectScrollbar>
            <Box className={styles.tabActions}>{actions}</Box>
        </Drawer>
    );
})

export interface TabProps extends Omit<DrawerProps, 'ref'> {
    classes?: Partial<DrawerClasses> & Partial<{ content: string }>;
    open: boolean;
    loading?: boolean;
    direction: 'left' | 'right';
    title: string;
    actions?: React.ReactNode;
    onFilter?: (queries: string[]) => void;
    filters?: string[];
    scrollTop?: number;
    onSearch?: (query: string) => void;
    onFileSelectAddition?: (e: any) => void;
    onAddition?: (e: any) => void;
    onClose?: (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void;
}

export default Tab;
