import { faClock, faLocationDot, faTurnUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography } from '@mui/material';
import { DataGrid, GridColDef, gridStringOrNumberComparator } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import dateUtils from 'services/dateUtils';
import styles from './TruckList.module.scss';

function TruckList(props: TruckListProps) {
    const { trucks, onTruckRowClick } = props;

    const { t } = useTranslation('common');
    const columns: GridColDef[] = [
        {
            field: 'nameAndDate',
            headerName: t('TruckList.Name'),
            flex: 1,
            filterable: false,
            valueGetter: (params) => {
                return {
                    name: params.row.name,
                    date: params.row.date,
                };
            },
            renderCell: (params) => (
                <Box display={'flex'} flexDirection={'column'} gap={'3px'} minWidth={'0%'}>
                    <Typography className={`${styles['cell-content']} ${styles['force-ellipsis']}`}>{params.value.name}</Typography>
                    <Box className={styles['cell-content-sub']}>
                        <FontAwesomeIcon icon={faClock} />
                        <Typography className={styles['force-ellipsis']}>{dateUtils.formatDetailedDate(params.value.date)}</Typography>
                    </Box>
                </Box>
            ),
            sortComparator: (v1, v2) => {
                return gridStringOrNumberComparator(v1.name, v2.name, null, null);
            },
        },
        {
            field: 'location',
            headerName: t('TruckList.Location'),
            flex: 1,
            filterable: false,
            sortable: false,
            renderCell: (params) => (
                <Box display={'flex'} flexDirection={'column'} gap={'3px'} className={styles['location-cell']} minWidth={'0%'}>
                    <Box display={'flex'} gap={'5px'} alignItems={'center'} minWidth={'0%'}>
                        <FontAwesomeIcon icon={faLocationDot} />
                        <Typography className={styles['force-ellipsis']}>{params.value.departure.name}</Typography>
                    </Box>
                    <Box display={'flex'} gap={'5px'} alignItems={'center'} minWidth={'0%'}>
                        <FontAwesomeIcon icon={faTurnUp} rotation={90} />
                        <Typography className={styles['force-ellipsis']}>{params.value.destination.name}</Typography>
                    </Box>
                </Box>
            ),
        },
        {
            field: 'layout',
            headerName: t('TruckList.Layout'),
            flex: 1,
            filterable: false,
            renderCell: (params) => (
                <Typography
                    className={`${styles['truck-layout']} ${styles['force-ellipsis']}`}
                    onClick={(e: any) => handleTruckLayoutClick(e, params.value.id)}>
                    {params.value.name}
                </Typography>
            ),
            sortComparator: (v1, v2) => {
                return gridStringOrNumberComparator(v1.name, v2.name, null, null);
            },
        },
        {
            field: 'items',
            headerName: t('TruckList.Items'),
            flex: 1,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                const packagesAmount = params.value.filter((item) => item.type === 'package').length;
                return `${params.value.length - packagesAmount} + ${packagesAmount}`;
            },
        },
        {
            field: 'createdBy',
            headerName: t('TruckList.CreatedBy'),
            flex: 1,
            filterable: false,
            renderCell: (params) => (
                <Box display={'flex'} gap={'7px'} alignItems={'center'} minWidth={'0%'}>
                    {params.value.avatar.URI ? (
                        <img src={params.value.avatar.URI} alt="profile avatar" />
                    ) : (
                        <Box
                            className={styles['user-avatar']}
                            display={'flex'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            sx={{ background: params.value.avatar.color }}>
                            <Typography>{params.value.avatar.letter}</Typography>
                        </Box>
                    )}
                    <Typography className={`${styles['cell-content']} ${styles['force-ellipsis']}`}>{params.value.name}</Typography>
                </Box>
            ),
            sortComparator: (v1, v2) => {
                return gridStringOrNumberComparator(v1.name, v2.name, null, null);
            },
        },
    ];

    const handleTruckLayoutClick = (e: any, id: string) => {
        e.stopPropagation();
        // TODO: go to truck layout configuration
    };

    return (
        <Box className={styles['data-grid-wrapper']}>
            <DataGrid
                rows={trucks}
                columns={columns}
                autoPageSize
                checkboxSelection
                disableRowSelectionOnClick
                classes={{
                    cellContent: styles['cell-content'],
                    cell: styles['cell'],
                    cellCheckbox: styles['cell'],
                    footerContainer: styles['data-grid-footer'],
                    checkboxInput: styles['checkbox-input'],
                    root: styles['data-grid-root'],
                    columnHeader: styles['cell-header'],
                }}
                onRowClick={(e: any) => onTruckRowClick(e.row)}
            />
        </Box>
    );
}

export default TruckList;

export interface TruckListProps {
    trucks: any[];
    onTruckRowClick: (truck: any) => void;
}
