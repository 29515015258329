import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { FormInputSelect } from 'components/Shared/FormComponents/FormInputSelect/FormInputSelect';
import { FormInputSelectMenuItem } from 'components/Shared/FormComponents/FormInputSelect/FormInputSelectMenuItem';
import { FormInputSwitch } from 'components/Shared/FormComponents/FormInputSwitch';
import { Viewer } from 'hsbshareviewer';
import { useEffect, useState } from 'react';
import { ChromePicker } from 'react-color';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ViewerLayers } from '../Helpers/ViewerLayers';
import styles from './ViewerSettings.module.scss';

export default function ViewerSettings(props?: ProjectSettingsProps) {
    const { projectID, viewerRef } = props;
    let { t } = useTranslation('common');
    const defaultValues = {
        camera: {
            position: 'orthographic',
        },
        visibility: {
            layers: {
                models: true,
                wireframe: true,
                info: true,
                label: true,
                issue: true,
                grid: false,
                simpleModel: false,
            },
            zones: {
                '-5': true,
                '-4': true,
                '-3': true,
                '-2': true,
                '-1': true,
                '0': true,
                '1': true,
                '2': true,
                '3': true,
                '4': true,
                '5': true,
            },
        },
        graphics: {
            showStats: false,
            background: '#e0dfda',
            fps: 30,
            antiAlias: 'msaa',
        },
    };
    const [colorPickerOpen, setColorPickerOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const { control, getValues, reset, setValue } = useForm({
        defaultValues: { ...defaultValues },
        mode: 'onChange',
    });
    const [backgroundColor, setBackgroundColor] = useState(getValues('graphics.background'));

    useEffect(() => {
        if (!projectID) {
            return;
        }

        const projectSettings = JSON.parse(localStorage.getItem(projectID))?.viewerSettings;
        if (projectSettings) {
            reset({ ...projectSettings });
            setBackgroundColor(projectSettings.graphics.background);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectID]);

    useEffect(() => {
        let observedViewer;
        if (viewerRef) {
            observedViewer = viewerRef;
            observedViewer.loadingProgress.onComplete(applyViewerSettings);
        }
        return () => {
            if (observedViewer) observedViewer.loadingProgress.RemoveOnComplete(applyViewerSettings);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewerRef]);

    const resetViewerSettings = () => {
        reset({ ...defaultValues });
        setBackgroundColor(getValues('graphics.background'));
        applyViewerSettings();
    };

    const applyViewerSettings = () => {
        handleCameraPositionChange(getValues('camera.position'));
        Object.entries(getValues('visibility.layers')).forEach(([key, value]) => {
            handleVisibilityLayersChange(value, ViewerLayers[key.toUpperCase()]);
        });
        handleVisibilityZoneChange();

        handleBackgroundChange(getValues('graphics.background'));
        (handleGraphicsFPSChange as any)(getValues('graphics.fps'));
        (handleGraphicsAntiAliasChange as any)(getValues('graphics.antiAlias'));
    };

    const handleCameraPositionChange = (position: string) => {
        if (position === 'perspective') {
            viewerRef.setCameraPerspective();
        } else {
            viewerRef.setCameraOrthoGraphic();
        }
        handleDataChange();
    };

    const handleVisibilityLayersChange = (checked: boolean, layer: ViewerLayers) => {
        viewerRef.ToggleRenderLayer(checked, layer);
        handleDataChange();
    };

    const handleVisibilityZoneChange = () => {
        var shown: number[] = [],
            hidden: number[] = [];
        Object.entries(getValues('visibility.zones')).forEach(([key, value]) => {
            if (value) {
                shown.push(Number(key));
            } else {
                hidden.push(Number(key));
            }
        });
        viewerRef.hideZone(...hidden);
        viewerRef.showZone(...shown);
        handleDataChange();
    };

    const handleBackgroundChange = (hex: string) => {
        setValue('graphics.background', hex);
        viewerRef.setBackground(hex);
        handleDataChange();
    };

    const handleGraphicsFPSChange = (value: 'unlimited' | 24 | 30 | 60) => {
        if (value === 'unlimited') {
            viewerRef.LimitFPS = false;
        } else {
            viewerRef.LimitFPS = true;
            viewerRef.FPS = value;
        }
        handleDataChange();
    };

    const handleGraphicsAntiAliasChange = (value: 'SSAA' | 'FXAA' | 'MSAA' | 'SMAA' | 'NONE') => {
        viewerRef.setAntiAlias(value);
        handleDataChange();
    };

    const handleDataChange = () => {
        const settings: any = { viewerSettings: getValues() };
        localStorage.setItem(props.projectID, JSON.stringify(settings));
    };

    return (
        <Box className={styles['viewer-settings-container']}>
            <Button className={styles['viewer-settings-button']} onClick={() => setIsOpen(!isOpen)}>
                <img src={process.env.PUBLIC_URL + '/icons/gears-solid.svg'} alt="Icon for Viewer Settings" height={'auto'} width={20} />
            </Button>
            <Box className={`${styles['viewer-settings-menu']} ${isOpen ? styles['menu-open'] : ''}`}>
                <Box>
                    <Typography className={styles['viewer-settings-header']}>
                        {t('ViewerSettings.Camera.Label')}
                        <IconButton aria-label="reset defaults" onClick={() => resetViewerSettings()}>
                            <RestartAltIcon />
                        </IconButton>
                    </Typography>
                    <Box className={styles['viewer-settings-body']}>
                        <FormInputSelect
                            name={'camera.position'}
                            control={control}
                            label={t('ViewerSettings.Camera.Position')}
                            classes={{ container: styles['input-container'] }}
                            onChange={(e: any) => handleCameraPositionChange(e.target.value)}>
                            <FormInputSelectMenuItem key="orthographic" value="orthographic">
                                Orthographic
                            </FormInputSelectMenuItem>
                            <FormInputSelectMenuItem key="perspective" value="perspective">
                                Perspective
                            </FormInputSelectMenuItem>
                        </FormInputSelect>
                    </Box>
                </Box>
                <Box>
                    <Typography className={styles['viewer-settings-header']}>{t('ViewerSettings.Visibility.Label')}</Typography>
                    <Box className={styles['viewer-settings-body']}>
                        <Box>
                            <Typography>{t('ViewerSettings.Visibility.Layers')}</Typography>
                            <Box className={styles['layers-container']}>
                                <FormInputSwitch
                                    classes={{ container: styles['switch-list-input'] }}
                                    name={'visibility.layers.models'}
                                    control={control}
                                    label={'models'}
                                    onChange={(e: any) =>
                                        handleVisibilityLayersChange(e.target.checked, ViewerLayers.MODELS)
                                    }></FormInputSwitch>
                                <FormInputSwitch
                                    classes={{ container: styles['switch-list-input'] }}
                                    name={'visibility.layers.wireframe'}
                                    control={control}
                                    label={'wireframe'}
                                    onChange={(e: any) =>
                                        handleVisibilityLayersChange(e.target.checked, ViewerLayers.WIREFRAME)
                                    }></FormInputSwitch>
                                <FormInputSwitch
                                    classes={{ container: styles['switch-list-input'] }}
                                    name={'visibility.layers.info'}
                                    control={control}
                                    label={'info'}
                                    onChange={(e: any) =>
                                        handleVisibilityLayersChange(e.target.checked, ViewerLayers.INFO)
                                    }></FormInputSwitch>
                                <FormInputSwitch
                                    classes={{ container: styles['switch-list-input'] }}
                                    name={'visibility.layers.label'}
                                    control={control}
                                    label={'label'}
                                    onChange={(e: any) =>
                                        handleVisibilityLayersChange(e.target.checked, ViewerLayers.LABELS)
                                    }></FormInputSwitch>
                                <FormInputSwitch
                                    classes={{ container: styles['switch-list-input'] }}
                                    name={'visibility.layers.issue'}
                                    control={control}
                                    label={'issue'}
                                    onChange={(e: any) =>
                                        handleVisibilityLayersChange(e.target.checked, ViewerLayers.ISSUES)
                                    }></FormInputSwitch>
                                <FormInputSwitch
                                    classes={{ container: styles['switch-list-input'] }}
                                    name={'visibility.layers.grid'}
                                    control={control}
                                    label={'grid'}
                                    onChange={(e: any) =>
                                        handleVisibilityLayersChange(e.target.checked, ViewerLayers.INFO)
                                    }></FormInputSwitch>
                                <FormInputSwitch
                                    classes={{ container: styles['switch-list-input'] }}
                                    name={'visibility.layers.simpleModel'}
                                    control={control}
                                    label={'simpleModel'}
                                    onChange={(e: any) =>
                                        handleVisibilityLayersChange(e.target.checked, ViewerLayers.SIMPLEMODEL)
                                    }></FormInputSwitch>
                            </Box>
                        </Box>
                        <Box marginTop={2}>
                            <Typography>{t('ViewerSettings.Visibility.Zones')}</Typography>
                            <Box display={'flex'} flexWrap={'wrap'} marginTop={1}>
                                <FormInputSwitch
                                    classes={{ container: styles['switch-list-input'] }}
                                    name={'visibility.zones.-5'}
                                    control={control}
                                    label={'-5'}
                                    onChange={() => handleVisibilityZoneChange()}></FormInputSwitch>
                                <FormInputSwitch
                                    classes={{ container: styles['switch-list-input'] }}
                                    name={'visibility.zones.-4'}
                                    control={control}
                                    label={'-4'}
                                    onChange={() => handleVisibilityZoneChange()}></FormInputSwitch>
                                <FormInputSwitch
                                    classes={{ container: styles['switch-list-input'] }}
                                    name={'visibility.zones.-3'}
                                    control={control}
                                    label={'-3'}
                                    onChange={() => handleVisibilityZoneChange()}></FormInputSwitch>
                                <FormInputSwitch
                                    classes={{ container: styles['switch-list-input'] }}
                                    name={'visibility.zones.-2'}
                                    control={control}
                                    label={'-2'}
                                    onChange={() => handleVisibilityZoneChange()}></FormInputSwitch>
                                <FormInputSwitch
                                    classes={{ container: styles['switch-list-input'] }}
                                    name={'visibility.zones.-1'}
                                    control={control}
                                    label={'-1'}
                                    onChange={() => handleVisibilityZoneChange()}></FormInputSwitch>
                                <FormInputSwitch
                                    classes={{ container: styles['switch-list-input'] }}
                                    name={'visibility.zones.0'}
                                    control={control}
                                    label={'0'}
                                    onChange={() => handleVisibilityZoneChange()}></FormInputSwitch>
                                <FormInputSwitch
                                    classes={{ container: styles['switch-list-input'] }}
                                    name={'visibility.zones.1'}
                                    control={control}
                                    label={'1'}
                                    onChange={() => handleVisibilityZoneChange()}></FormInputSwitch>
                                <FormInputSwitch
                                    classes={{ container: styles['switch-list-input'] }}
                                    name={'visibility.zones.2'}
                                    control={control}
                                    label={'2'}
                                    onChange={() => handleVisibilityZoneChange()}></FormInputSwitch>
                                <FormInputSwitch
                                    classes={{ container: styles['switch-list-input'] }}
                                    name={'visibility.zones.3'}
                                    control={control}
                                    label={'3'}
                                    onChange={() => handleVisibilityZoneChange()}></FormInputSwitch>
                                <FormInputSwitch
                                    classes={{ container: styles['switch-list-input'] }}
                                    name={'visibility.zones.4'}
                                    control={control}
                                    label={'4'}
                                    onChange={() => handleVisibilityZoneChange()}></FormInputSwitch>
                                <FormInputSwitch
                                    classes={{ container: styles['switch-list-input'] }}
                                    name={'visibility.zones.5'}
                                    control={control}
                                    label={'5'}
                                    onChange={() => handleVisibilityZoneChange()}></FormInputSwitch>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box>
                    <Typography className={styles['viewer-settings-header']}>{t('ViewerSettings.Graphics.Label')}</Typography>
                    <Box className={styles['viewer-settings-body']}>
                        <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                            <Typography sx={{ flexBasis: '100%', color: '#000', fontSize: '14px', fontWeight: '500' }}>
                                {t('ViewerSettings.Graphics.Background')}
                            </Typography>
                            <Box
                                sx={{ backgroundColor: backgroundColor }}
                                className={styles['color-picker-preview']}
                                onClick={() => setColorPickerOpen(true)}></Box>
                            {colorPickerOpen && (
                                <Box className={styles['popover']}>
                                    <Box className={styles['cover']} draggable={false} onClick={() => setColorPickerOpen(false)} />
                                    <ChromePicker
                                        name={'graphics.background'}
                                        control={control}
                                        label={'Background'}
                                        disableAlpha={true}
                                        onChangeComplete={(e: any) => handleBackgroundChange(e.hex)}
                                        onChange={(e: any) => setBackgroundColor(e.hex)}
                                        color={backgroundColor}
                                    />
                                </Box>
                            )}
                        </Box>
                        <Box sx={{ marginBottom: 1 }}>
                            <FormInputSelect
                                name={'graphics.fps'}
                                control={control}
                                label={t('ViewerSettings.Graphics.FPS')}
                                onChange={(e: any) => handleGraphicsFPSChange(e.target.value)}
                                classes={{ container: styles['input-container'] }}>
                                <FormInputSelectMenuItem key="24" value="24">
                                    24
                                </FormInputSelectMenuItem>
                                <FormInputSelectMenuItem key="30" value="30">
                                    30
                                </FormInputSelectMenuItem>
                                <FormInputSelectMenuItem key="60" value="60">
                                    60
                                </FormInputSelectMenuItem>
                                <FormInputSelectMenuItem key="unlimited" value="unlimited">
                                    Unlimited
                                </FormInputSelectMenuItem>
                            </FormInputSelect>
                        </Box>
                        <Box>
                            <FormInputSelect
                                name={'graphics.antiAlias'}
                                control={control}
                                label={t('ViewerSettings.Graphics.AntiAlias')}
                                classes={{ container: styles['input-container'] }}
                                onChange={(e: any) => handleGraphicsAntiAliasChange(e.target.value)}>
                                <FormInputSelectMenuItem key="ssaa" value="ssaa">
                                    SSAA
                                </FormInputSelectMenuItem>
                                <FormInputSelectMenuItem key="fxaa" value="fxaa">
                                    FXAA
                                </FormInputSelectMenuItem>
                                <FormInputSelectMenuItem key="msaa" value="msaa">
                                    MSAA
                                </FormInputSelectMenuItem>
                                <FormInputSelectMenuItem key="smaa" value="smaa">
                                    SMAA
                                </FormInputSelectMenuItem>
                                <FormInputSelectMenuItem key="none" value="none">
                                    NONE
                                </FormInputSelectMenuItem>
                            </FormInputSelect>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export interface ProjectSettingsProps {
    projectID?: string;
    viewerRef: Viewer;
}
