import { faTruckMoving } from "@fortawesome/free-solid-svg-icons";
import { Box, Typography } from "@mui/material";
import ImageOrPlaceholder from "components/Shared/ImageOrPlaceholder/ImageOrPlaceholder.module";
import dateUtils from "services/dateUtils";
import propertiesStyles from '../../../styles/Properties.module.scss';
import styles from './TruckConfigurationItem.module.scss';

function TruckConfigurationItem(props: TruckConfigurationItemProps) {
    const { truckConfiguration } = props;

    const handleTruckClick = () => {
        const truckId = truckConfiguration._id;
        // TODO: handle click
    }

    return (
        <Box className={styles['truck-item-container']} onClick={handleTruckClick}>
            <ImageOrPlaceholder imageURL={null} placeholderIcon={faTruckMoving} />
            <Box className={styles['truck-item-content-container']}>
                <Box className={styles['truck-item-header-container']}>
                    <Typography className={styles['truck-item-name']}>{truckConfiguration.name}</Typography>
                    <Typography className={styles['truck-item-updated-on']}>{dateUtils.formatDetailedDate(truckConfiguration.lastUpdatedOn)}</Typography>
                </Box>
                <Box className={styles['truck-item-detail-container']}>
                    <Typography className={styles['truck-item-description']}>{truckConfiguration.description}</Typography>
                    <Box className={`${propertiesStyles[truckConfiguration.state]} ${styles['truck-item-state']}`}>
                        <span>{truckConfiguration.state.charAt(0)}</span>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default TruckConfigurationItem

export interface TruckConfigurationItemProps {
    truckConfiguration: any;
}