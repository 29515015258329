import { blue } from '@mui/material/colors';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import colors from 'styles/colors.module.scss';

// Create a theme instance.
/*


https://material.io/resources/color/#!/?view.left=0&view.right=1&primary.color=f9af08&secondary.color=575757
https://material-ui.com/customization/color/


*/

declare module '@mui/material/styles' {
    interface Palette {
        icon: Palette['primary'];
        state: {
            open: string;
            onhold: string;
            closed: string;
            resolved: string;
            active: string;
        };
    }
    interface PaletteOptions {
        icon?: Palette['primary'];
        state?: {
            open: string;
            onhold: string;
            closed: string;
            resolved: string;
            active: string;
        };
    }

    interface PaletteColor {
        extraLight?: string;
    }
    interface SimplePaletteColorOptions {
        extraLight?: string;
    }
}

declare module '@mui/material/ToggleButtonGroup' {
    interface ToggleButtonGroupPropsColorOverrides {
        selectedColor?: string;
    }
}

declare module "@mui/material/Typography" {
    interface TypographyPropsVariantOverrides {
        error: true;
        warning: true;
    }
}

const palette = {
    primary: {
        light: colors.primaryLight,
        main: colors.primary,
        dark: colors.primaryDark,
        contrastText: colors.primaryContrast,
    },
    secondary: {
        light: colors.secondaryLight,
        main: colors.secondary,
        dark: colors.secondaryDark,
    },
    error: {
        light: colors.errorLight,
        main: colors.error,
        dark: colors.errorDark,
        contrastText: colors.primaryContrast,
        lighter: colors.errorLighter,
    },
    warning: {
        light: colors.warningLight,
        main: colors.warning,
        dark: colors.warningDark,
        lighter: colors.warningLighter,
    },
    icon: {
        light: colors.iconLight,
        main: colors.icon /* b71c1c */,
        dark: colors.iconDark,
        contrastText: colors.primaryContrast,
        lighter: colors.iconLighter,
    },
    state: {
        open: colors.stateOpen,
        onhold: colors.stateOnHold,
        closed: colors.stateClosed,
        resolved: colors.stateResolved,
        active: colors.stateActive,
    },
    background: {
        default: colors.background,
    },
    text: {
        primary: colors.textPrimary,
        secondary: colors.textSecondary,
        third: colors.textThird,
        disabled: colors.textDisabled,
    },
    link: {
        light: blue[600],
        main: blue[700] /* b71c1c */,
        dark: blue[900],
        extraLight: blue[400],
    }
};

const theme = responsiveFontSizes(
    createTheme({
        palette: palette,
        components: {
            MuiDivider: {
                defaultProps: {
                    style: {
                        background: palette.background.default,
                    },
                },
            },
            MuiOutlinedInput: {
                defaultProps: {
                    sx: {
                        fontWeight: 400,
                        height: 37.12,
                    },
                },
            },
            MuiLink: {
                defaultProps: {
                    sx: {
                        color: palette.link.main,
                        borderBottom: 0,
                        '&:hover': {
                            borderBottom: `1px solid ${palette.link.main}`
                        }

                    }
                },
            },
            MuiFormHelperText: {
                defaultProps: {
                    sx: {
                        color: palette.primary.main,
                    },
                },
            },
            MuiTab: {
                defaultProps: {
                    sx: {
                        textTransform: 'capitalize',
                        borderBottom: '1px solid',
                    },
                },
            },
            MuiPaper: {
                variants: [
                    {
                        props: { elevation: 2 },
                        style: {
                            boxShadow: '0px 0px 5px 0px rgb(0 0 0 / 25%)',
                        },
                    },
                ],
            },
            MuiDialog: {
                styleOverrides: {
                    paper: {
                        padding: '35px',
                        width: '75%',
                    },
                },
            },
            MuiDialogTitle: {
                styleOverrides: {
                    root: {
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: 0,
                        fontSize: '1.75rem !important',
                        position: 'relative',
                        zIndex: 0,
                        '& button svg': {
                            fontSize: '2.1rem',
                        },
                    },
                },
                variants: [
                    {
                        props: { variant: 'error' },
                        style: {
                            color: '#fff',
                            paddingBottom: '25px',
                            button: {
                                color: 'inherit',
                            },
                            '&::before': {
                                content: '""',
                                backgroundColor: '#e53935',
                                position: 'absolute',
                                left: '-35px',
                                top: '-35px',
                                right: '-35px',
                                bottom: 0,
                                zIndex: -1,
                            },
                        },
                    },
                    {
                        props: { variant: 'warning' },
                        style: {
                            color: '#fff',
                            paddingBottom: '25px',
                            button: {
                                color: 'inherit',
                            },
                            '&::before': {
                                content: '""',
                                backgroundColor: '#f6a81c',
                                position: 'absolute',
                                left: '-35px',
                                top: '-35px',
                                right: '-35px',
                                bottom: 0,
                                zIndex: -1,
                            },
                        },
                    },
                ],
            },
            MuiDialogContent: {
                defaultProps: {
                    sx: {
                        margin: '18px 0 0 0',
                        padding: 0,
                        overflowY: 'unset',
                    },
                },
            },
            MuiDialogActions: {
                styleOverrides: {
                    root: {
                        marginTop: '18px',
                        padding: 0,
                        justifyContent: 'flex-start',
                        '& button[type="submit"]': {
                            width: '100%',
                            '&:nth-of-type(2n)': {
                                marginLeft: '16px',
                            },
                        },
                    },
                },
            },
        },
        typography: {
            fontSize: 14,
            fontWeightRegular: 500
        },
    })
);

export default theme;
export { palette };
